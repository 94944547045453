import { Injectable } from '@angular/core';
import { Auth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from '@angular/fire/auth';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root'
})
export class CambioPassServiceService {

  constructor(private auth: Auth, private alertService: AlertService) {}

  async cambiarContraseña(actualPass: string, newPass: string): Promise<void> {
    const user = this.auth.currentUser;

    if (user) {
      const credential = EmailAuthProvider.credential(user.email!, actualPass);

      try {
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPass);
        this.alertService.showConfirmMessageSuccess('Contraseña actualizada correctamente.');
      } catch (error) {
        this.alertService.showConfirmMessageError('Error al actualizar la contraseña: ' + error);
      }
    } else {
      this.alertService.showConfirmMessageError('No se ha podido autenticar al usuario.');
    }
  }
}
