import { Injectable } from '@angular/core';
import { Auth, sendPasswordResetEmail } from '@angular/fire/auth';
import { AlertService } from '../alert.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RecuperarService {

  constructor(private auth: Auth,private alertService: AlertService,private router: Router) {}

  enviarCorreoRecuperacion(email: string) {
    return sendPasswordResetEmail(this.auth, email)
      .then(() => {
        this.alertService.showConfirmMessageSuccess('Correo de recuperación enviado exitosamente.\nRevise su correo y vuelva a ingresar.');
        this.router.navigate(['/login']);
      })
      .catch((error) => {
        this.alertService.showConfirmMessageError('Error al enviar el correo de recuperación: ' + error.message);
      });
  }
}
