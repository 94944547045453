<div class="container d-flex flex-column align-items-center mt-3">
  <div class="row justify-content-center">
    <!-- Tarjeta 1: Teléfono -->
    <div class="col-md-4 mb-4">
      <a target="_blank" class="text-decoration-none text-dark"> 
        <div class="card text-center">
          <div class="card-body">
            <mat-icon class="icon">phone</mat-icon>
            <h5 class="card-title">Teléfono</h5>
            <p class="card-text">(+549) 3888 468916</p>
          </div>
        </div>
      </a>
    </div>

    <!-- Tarjeta 2: Correo -->
    <div class="col-md-4 mb-4 pointer-cursor">
      <a href="mailto:fvorepuestos@gmail.com" class="text-decoration-none text-dark">
        <div class="card text-center">
          <div class="card-body">
            <mat-icon class="icon">email</mat-icon>
            <h5 class="card-title">Correo</h5>
            <p class="card-text">fvorepuestos@gmail.com</p>
          </div>
        </div>
      </a>
    </div>

    <!-- Tarjeta 3: Dirección -->
    <div class="col-md-4 mb-4 pointer-cursor">
      <a href="https://www.google.com/maps/search/?api=1&query=Ecuador+219%2C+San+Pedro+de+Jujuy%2C+Jujuy" target="_blank" class="text-decoration-none text-dark">
        <div class="card text-center">
          <div class="card-body">
            <mat-icon class="icon">location_on</mat-icon>
            <h5 class="card-title">Dirección</h5>
            <p class="card-text">Ecuador 219, San Pedro de Jujuy, Jujuy</p>
          </div>
        </div>
      </a>
    </div>
  </div>
  <div class="horarios-container mt-1">
    <div class="titulo">Horarios de atención:</div>
    <div class="detalles mt-2">
      <p>Lunes – Viernes: 9:00-18:00</p>
      <p>Sábado: 8:30 – 13:00</p>
    </div>
  </div>
  <div class="somos col-6 mt-2">
    <div class="titulo mb-2">¿Quienes somos?</div>
    <p>Fvo repuestos es una empresa joven pero que cuenta con mucha experiencia en el rubro autopartista,
      Nos especializamos en Electricidad, Encendido, Inyeccion electronica e Iluminacion Automotriz y motocicletas.</p>
  </div>
</div>