<div *ngIf="loading" class="text-center" style="margin-top: 8rem;">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Cargando...</p>
</div>
<div class="container" *ngIf="!loading">
  <!-- Contenedor de filtros -->
  <div class="pinguito col-10 p-1 my-2">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-5 col-md-4 me-2"> <!-- columna izquierda -->
        <label for="filtroGeneralInput" class="mt-1 ms-1">Filtrar todo</label>
        <input type="text" id="filtroGeneralInput" class="form-control ms-1 mb-1" [(ngModel)]="filtroGeneral"
          (keyup.enter)="filtrarProductos()">
        <label for="codigoInput" class="mt-1 ms-1">Filtrar por Código</label>
        <input type="text" id="codigoInput" class="form-control mb-1 ms-1" [formControl]="codigoFiltrado"
          [matAutocomplete]="autoCodigo">
        <mat-autocomplete #autoCodigo="matAutocomplete">
          <mat-option *ngFor="let codigo of filteredCodigos | async" [value]="codigo">
            {{ codigo }}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-12 col-sm-5 col-md-4 me-2"> <!-- columna derecha -->
        <label for="marcaSelect" class="mt-1 ms-1">Filtrar por Marca</label>
        <select id="marcaSelect" class="form-select mb-1 ms-1" [formControl]="marcaFiltrada">
          <option value="">Todas las marcas</option>
          <option *ngFor="let marca of filteredMarcas | async" [value]="marca">
            {{ marca }}
          </option>
        </select>
        <label for="rubroSelect" class="mt-1 ms-1">Filtrar por Rubro</label>
        <select id="rubroSelect" class="form-select mb-1 ms-1" [formControl]="rubroFiltrado">
          <option value="">Todas los rubros</option>
          <option *ngFor="let rubro of filteredRubros | async" [value]="rubro">
            {{ rubro }}
          </option>
        </select>
      </div>

      <!-- Botones -->
      <div class="col-md-2 col-sm-4 d-flex flex-column justify-content-center align-items-center mt-4"
        style="gap: 1rem;">
        <button class="btn btn-success btnFiltro w-100" (click)="filtrarProductos()">
          <mat-icon>search</mat-icon> Buscar
        </button>
        <button class="btn btn-danger btnFiltro w-100" (click)="limpiarFiltros()">
          <mat-icon>delete</mat-icon> Limpiar
        </button>
      </div>
    </div>
  </div>
  <!-- COMIENZA EL CONTENIDO PRINCIPAL -->
  <div class="col-12" style="padding: 8px 24px;">
    <!-- Botones de vista -->
    <div class="d-flex justify-content-start mb-2">
      <button class="btn btn-dark me-2" [class.active]="isGridView" (click)="toggleView('grid')"
        matTooltip="Vista Cuadricula" matTooltipShowDelay="500" matTooltipPosition="above">
        <mat-icon>grid_on</mat-icon>
      </button>
      <button class="btn btn-dark" [class.active]="!isGridView" (click)="toggleView('list')" matTooltip="Vista Lista"
        matTooltipShowDelay="500" matTooltipPosition="above">
        <mat-icon>list</mat-icon>
      </button>
      <h1 class="flex-grow-1 text-center">{{tituloCatalogo}}</h1>
    </div>


    <!-- Vista de productos -->
    <div *ngIf="isGridView; else listView">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let producto of paginatedProducts">
          <div class="card h-100">
            <img [src]="imagesUrls[producto.codigo] || 'assets/noimagen.png'" class="card-img-top pointer-cursor"
              (click)="imagesUrls[producto.codigo] ? verImagen(producto) : null">
            <div class="card-body d-flex flex-column">
              <div class="d-flex justify-content-between mb-2">
                <h5 class="card-title mb-1" style="font-style: italic;">Marca</h5>
                <h5 class="card-title mb-1" style="font-style: italic;">Precio</h5>
              </div>
              <div class="d-flex justify-content-between mb-2">
                <h5 class="card-title mb-1" style="font-size: 14px; font-weight: bold;">{{ producto.marca }}</h5>
                <h5 class="card-title mb-1" style="font-size: 14px; font-weight: 550;">{{ producto.precio |
                  currency:'USD':'symbol' }}</h5>
              </div>
              <p class="card-text mb-2" style="font-weight: 550;">{{ producto.descripcion }}</p>
              <div class="mt-auto d-flex justify-content-between align-items-center">
                <button class="btn btn-dark" (click)="agregarProducto(producto)"
                  [disabled]="producto.stock === 'Sin Stock'" matTooltip="Añadir al carrito" matTooltipShowDelay="500"
                  matTooltipPosition="above">
                  <mat-icon>add_shopping_cart</mat-icon>
                </button>
                <h5 class="card-title mb-0" style="font-weight: bold;">Cod: {{ producto.codigo }}</h5>
                <p class="stock mb-0" [style.color]="producto.stock === 'Sin Stock' ? 'red' : 'green'">
                  {{ producto.stock }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Vista de lista -->
    <ng-template #listView>
      <div class="table-responsive">
        <table mat-table [dataSource]="paginatedProducts" class="mat-elevation-z8">
          <ng-container matColumnDef="codigo">
            <th mat-header-cell *matHeaderCellDef> Código </th>
            <td mat-cell *matCellDef="let producto"> {{ producto.codigo }} </td>
          </ng-container>
          <ng-container matColumnDef="descripcion">
            <th mat-header-cell *matHeaderCellDef> Descripción </th>
            <td mat-cell *matCellDef="let producto"> {{ producto.descripcion }} </td>
          </ng-container>
          <ng-container matColumnDef="precio">
            <th mat-header-cell *matHeaderCellDef> Precio </th>
            <td mat-cell *matCellDef="let producto"> {{ producto.precio | currency:'USD':'symbol' }} </td>
          </ng-container>
          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef> Stock </th>
            <td mat-cell *matCellDef="let producto"> {{ producto.stock }} </td>
          </ng-container>
          <ng-container matColumnDef="agregar">
            <th mat-header-cell *matHeaderCellDef> Añadir </th>
            <td mat-cell *matCellDef="let producto">
              <button class="btn btn-dark" (click)="agregarProducto(producto)" matTooltip="Añadir al carrito"
                matTooltipShowDelay="500" matTooltipPosition="above">
                <mat-icon>add_shopping_cart</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="verImagen">
            <th mat-header-cell *matHeaderCellDef> Ver Imagen </th>
            <td mat-cell *matCellDef="let producto">
              <button *ngIf="imagesUrls[producto.codigo]" class="btn btn-info ms-2" (click)="verImagen(producto)"
                matTooltip="Ver Imagen" matTooltipShowDelay="500" matTooltipPosition="above">
                <mat-icon>visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['codigo', 'descripcion', 'precio', 'stock', 'agregar', 'verImagen']">
          </tr>
          <tr mat-row
            *matRowDef="let row; columns: ['codigo', 'descripcion', 'precio', 'stock', 'agregar', 'verImagen'];"></tr>
        </table>
      </div>
    </ng-template>



    <!-- Paginador -->
    <div class="paginator" style="background-color: lightskyblue; padding: 1px;">
      <mat-paginator [length]="productosFiltrados.length" [pageSize]="pageSize" [pageSizeOptions]="[12, 24, 36, 48]"
        showFirstLastButtons (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </div>

  <!-- Mensaje si no hay productos filtrados -->
  <ng-template #noProductos>
    <p>No se encontraron productos.</p>
  </ng-template>
</div>