// cuenta-corriente.component.ts
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Observable, of } from 'rxjs';
import { FacturaService } from './factura.service';

@Component({
  selector: 'app-cuenta-corriente',
  templateUrl: './cuenta-corriente.component.html',
  styleUrls: ['./cuenta-corriente.component.css']
})
export class CuentaCorrienteComponent implements OnInit {
  facturas: Observable<any[]> = of([]);
  detalles!: Observable<any[]>;
  mostrarModal: boolean = false;
  facturaSeleccionada: any;
  loading: boolean = true;

  constructor(private loginService: LoginService,
              private facturaService:FacturaService
  ) { }

  ngOnInit(): void {
    this.cargarFacturas();
  }

  cargarFacturas(): void {
    const userEmail = this.loginService.getEmail();
    if (userEmail) {
      this.facturas = this.facturaService.getFacturasConDetalles(userEmail);
      this.facturas.subscribe({
        next: () => this.loading = false,
        error: (err) => {
          console.error('Error al cargar las facturas:', err);
          this.loading = false;
        }
      });
    }
  }
  abrirModal(factura: any): void {
    this.facturaSeleccionada = factura;
    this.detalles = this.facturaService.getDetalle(factura.userEmail, factura.numeroDePedido);
    this.mostrarModal = true;
  }

  cerrarModal(): void {
    this.mostrarModal = false;
    this.facturaSeleccionada = null;
  }
}
