<div class="app-container">
  <!-- Header -->
  <header class="d-flex flex-column flex-md-row align-items-center p-3 bg-white text-black">
    <img src="assets/LogoFVO.png" alt="Logo" class="img-fluid pointer-cursor mb-2 mb-md-0" [routerLink]="['/principal/home']">
    <h1 class="mx-auto text-center text-md-left d-none d-md-block">Bienvenido a FVO Repuestos!</h1>
  </header>

  <!-- SubHeader -->
  <div class="subheader">
    <!-- Botón flotante para abrir el sidenav -->
    <div>
      <button mat-icon-button (click)="toggleSidebar()" [class.active-button]="!sidebarPinned && !sidebarCollapsed"
              matTooltip="Menu Lateral" matTooltipShowDelay="500" matTooltipPosition="above">
        <mat-icon>{{ sidebarCollapsed ? 'menu' : 'undo' }}</mat-icon>
      </button>
      <button mat-icon-button (click)="keepSidebar()"  [class.active-button]="sidebarPinned" 
              matTooltip="Anclar/Desanclar Menu Lateral" matTooltipShowDelay="500" matTooltipPosition="above">
        <mat-icon>push_pin</mat-icon>
      </button>
      <button *ngIf="admin" mat-icon-button routerLink="admin">
        <mat-icon>manage_accounts</mat-icon>
      </button>
    </div>
    <!-- Carrusel de noticias -->
    <div class="carousel d-none d-md-block">
      <p class="carousel-text">{{textoBanner}}</p>
    </div>
    <!-- Bienvenido Usuario -->
    <div class="float-end d-flex align-items-center">
      <mat-icon style="color: white;">account_circle</mat-icon>
      <span class="ms-2" style="color: white;">Hola {{ usuario }}!</span>
    </div>
  </div>
  

  <!-- Sidenav (fuera del mat-sidenav-container) -->
  <mat-sidenav-container id="sidenav-container" class="sidenav-wrapper" [class.sidenav-opened]="!sidebarCollapsed" 
      [class.minimized]="sidebarCollapsed">
    <mat-sidenav #sidenav [mode]="sidebarCollapsed ? 'over' : 'side'" [opened]="!sidebarCollapsed">
      <mat-nav-list class="mat-nav-list">
        <!-- Opciones del menú lateral -->
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/home" routerLinkActive="active">
          <button mat-button>
            <mat-icon>home</mat-icon>
            Inicio
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/catalogo" routerLinkActive="active">
          <button mat-button>
            <mat-icon>list</mat-icon>
            Catálogo
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/mis-pedidos" routerLinkActive="active">
          <button mat-button>
            <mat-icon>shopping_cart</mat-icon>
            Carrito
          </button>
          <span class="cart-count" *ngIf="cartCount > 0">{{ cartCount }}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/cuenta-corriente" routerLinkActive="active">
          <button mat-button>
            <mat-icon>request_quote</mat-icon>
            Pedidos
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/perfil" routerLinkActive="active">
          <button mat-button>
            <mat-icon>account_circle</mat-icon>
            Perfil
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/cambio-pass" routerLinkActive="active">
          <button mat-button style="font-size: 15px;">
            <mat-icon>lock</mat-icon>
            Cambiar Contraseña
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item routerLink="/principal/contacto" routerLinkActive="active">
          <button mat-button>
            <mat-icon>contact_phone</mat-icon>
            Contactanos
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="logout()">
          <button mat-button>
            <mat-icon>logout</mat-icon>
            Salir
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </mat-sidenav>
  </mat-sidenav-container>

  <!-- Contenido principal (fuera del mat-sidenav-container) -->
  <div (click)="toggleSidebarFromContent()" class="principal container-fluid background">
    <div class="circle"></div>
    <div class="wave"></div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
