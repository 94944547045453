import { Component, HostListener, OnInit } from '@angular/core';
import { LoginService } from '../login/login.service';
import { DatosPerfilService } from '../perfil/datos.perfil.service';
import { PageAdminService } from '../pageAdmin/page-admin.service';
import { CarritoService } from '../carrito.service';
import { AlertService } from '../alert.service';
import { PrincipalService } from './principal.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit{
  admin=false;
  sidebarCollapsed = true;
  sidebarPinned = false;
  activeComponent: string | null = null;
  cartCount : number = 0;
  usuario: string | null = null;
  textoBanner : string = '';
  // Propiedad para almacenar la posición top
  private initialTop = 148;
  private scrolledTop = 48;
  constructor(
    private loginService: LoginService,
    private datosService: DatosPerfilService,
    private pageAdminService:PageAdminService,
    private carritoService: CarritoService,
    private alertService:AlertService,
    private principalService:PrincipalService
  ) { }

  ngOnInit(): void {
    this.updateSidenavPosition();
    this.cargarBanners();
    this.datosService.getApYNomOrEmail().then(usuario => {
      this.usuario = usuario;
    });
    this.pageAdminService.isAdmin().subscribe(isAdmin => {
      this.admin = isAdmin;
    });
    this.carritoService.allItemsCarrito().subscribe({
      next: (count) => {
        this.cartCount = count;
      },
      error: (error) => {
        console.error('Error al obtener el número de productos en el carrito:', error);
      }
    });
  }
  cargarBanners(): void {
    this.principalService.getBannerTexts().subscribe({
      next: (texts: string[]) => {
        const espacioLargo = '                                                                                                                                                            ';
        this.textoBanner = texts.join(espacioLargo + espacioLargo);
      },
      error: (err) => {
        console.error('Error al cargar los textos del banner:', err);
      }
    });
  }
  toggleSidebar() {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    this.sidebarPinned = false;
  }
  toggleSidebarFromContent() {
    if (!this.sidebarPinned && !this.sidebarCollapsed) {
      this.sidebarCollapsed = true;
    }
  }
  keepSidebar() {
    this.sidebarCollapsed = false;
    this.sidebarPinned = !this.sidebarPinned;
  }
  sidebarMode() {
    return this.sidebarCollapsed ? 'over' : 'side';
  }
  showComponent(componentName: string) {
    this.activeComponent = componentName;
  }
  async logout(): Promise<void> {
    try {
      // Mostrar alerta de confirmación
      const confirmMessage = "¿Está seguro de que desea cerrar sesión?";
      const confirmResponse = await this.alertService.showYesNoMessage(confirmMessage);
  
      // Verificar si el usuario confirmó la acción
      if (confirmResponse) {
        this.loginService.logout();
      }
    } catch (error) {
      this.alertService.showErrorMessage('Error al cerrar sesión');
    }
  }
  
  
  // Listener para detectar el scroll
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event) {
    this.updateSidenavPosition();
  }
  private updateSidenavPosition() {
    const scrollPosition = window.scrollY;
    const sidenavWrapper = document.getElementById('sidenav-container');

    if (sidenavWrapper) {
      const midpoint = (this.initialTop + this.scrolledTop) / 2;
      if (scrollPosition > this.initialTop) {
        sidenavWrapper.style.top = `${this.scrolledTop}px`;
        sidenavWrapper.style.height = `calc(73vh - ${this.scrolledTop}px)`;
      } else if (scrollPosition > midpoint) {
        sidenavWrapper.style.top = `${this.scrolledTop}px`;
        sidenavWrapper.style.height = `calc(88.5vh - ${this.initialTop}px)`;
      } else {
        sidenavWrapper.style.top = `128px`;
        sidenavWrapper.style.height = `calc(88.5vh - ${this.initialTop}px)`;
      }
    }
  }
}
