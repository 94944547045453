import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private storage: AngularFireStorage) {}

  getImageUrlByCode(code: string): Observable<string> {
    const imageRef = this.storage.ref(`FOTOS/${code}.jpg`);
    
    return imageRef.getDownloadURL();
  }
}
