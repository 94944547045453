import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alert.service';
import { RecuperarService } from './recuperar.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent {
  recuperarForm: FormGroup;
  
  constructor(private fb: FormBuilder, 
    private recuperarService: RecuperarService, 
    private alertService: AlertService) {
    this.recuperarForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {}

  enviar() {
    if (this.recuperarForm.valid) {
      const email = this.recuperarForm.get('email')!.value;
      this.recuperarService.enviarCorreoRecuperacion(email);
    } else {
      this.alertService.showConfirmMessageError('Por favor ingrese un correo válido');
    }
  }

  volver() {
    this.recuperarForm.reset();
  }
}

