import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { CambioPassComponent } from './cambio-pass/cambio-pass.component';
import { PrincipalComponent } from './principal/principal.component';
import { PerfilComponent } from './perfil/perfil.component';
import { CuentaCorrienteComponent } from './cuenta-corriente/cuenta-corriente.component';
import { ContactoComponent } from './contacto/contacto.component';
import { RegisterComponent } from './register/register.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { LoginGuard } from './login/login-guardian.service';
import { PageAdminComponent } from './pageAdmin/page-admin.component';
import { HomeComponent } from './home/home.component';
const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // Ruta inicial redirige a login
  { path: 'login', component: LoginComponent }, // Componente de login
  { path: 'register', component: RegisterComponent }, // Componente de registro
  { path: 'recovery', component: RecoveryComponent }, // Componente de recuperacion
  {
    path: 'principal', component: PrincipalComponent, canActivate: [LoginGuard], children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'catalogo', component: CatalogoComponent },
      { path: 'mis-pedidos', component: PedidosComponent },
      { path: 'cambio-pass', component: CambioPassComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'cuenta-corriente', component: CuentaCorrienteComponent },
      { path: 'contacto', component: ContactoComponent },
      { path: 'admin', component: PageAdminComponent }
    ]
  },
  { path: '**', redirectTo: '/login' } // Ruta para manejar rutas no encontradas redirige a login
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
