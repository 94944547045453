import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc } from '@angular/fire/firestore';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {
  constructor(private firestore: Firestore) {}

  getBannerTexts(): Observable<string[]> {
    const configuracionDoc = doc(this.firestore, 'configuracion/1');
    return from(getDoc(configuracionDoc)).pipe(
      map(snapshot => {
        const data = snapshot.data();
        if (data) {
          return [data['Banner1'], data['Banner2'], data['Banner3']].filter(Boolean); // Filtra null o undefined
        }
        return [];
      })
    );
  }
}