<div class="login-container d-flex justify-content-center align-items-center vh-100">
  <div class="login-box p-4 col-md-4 col-sm-9 shadow rounded">
    <div class="text-center mb-4">
      <img src="assets/LogoFVO.png" alt="Login Image" class="img-fluid">
    </div>
    <form #f="ngForm" (ngSubmit)="login(f)">
      <div class="form-group row mb-3">
        <label for="email" class="col-sm-4 col-form-label">Correo:</label>
        <div class="col-sm-8">
          <input type="text" class="form-control" id="email" name="email" ngModel required email>
          <div *ngIf="f.submitted || f.controls['email']?.touched">
            <div *ngIf="f.controls['email']?.errors?.['required']" class="text-danger">El correo es obligatorio</div>
            <div *ngIf="f.controls['email']?.errors?.['email']" class="text-danger">El correo no es válido</div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label for="password" class="col-sm-4 col-form-label">Contraseña:</label>
        <div class="col-sm-8">
          <input type="password" class="form-control" id="password" name="password" ngModel required minlength="6">
          <div *ngIf="f.submitted || f.controls['password']?.touched">
            <div *ngIf="f.controls['password']?.errors?.['required']" class="text-danger">La contraseña es obligatoria</div>
            <div *ngIf="f.controls['password']?.errors?.['minlength']" class="text-danger">La contraseña debe tener al menos 6 caracteres</div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="/recovery" class="forgot-password d-block mb-3">¿Olvidó su contraseña?</a>
        <button type="submit" class="login-button">Ingresar</button>
        <button type="button" class="signup-button" (click)="registrar()">Registrarse</button>
      </div>
    </form>
  </div>
</div>