import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { DatosPerfilService } from './datos.perfil.service';
import { Datos } from './datos.interface';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit{
  perfilForm: FormGroup;
  loading = true;
  provincias: string[] = [
    'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'CABA', 'Córdoba', 'Corrientes', 'Entre Ríos',
    'Formosa', 'Jujuy', 'La Pampa', 'La Rioja', 'Mendoza', 'Misiones', 'Neuquén', 'Río Negro',
    'Salta', 'San Juan', 'San Luis', 'Santa Cruz', 'Santa Fe', 'Santiago del Estero', 'Tierra del Fuego',
    'Tucumán'
  ];

  constructor(
    private fb: FormBuilder,
    private datosPerfilService: DatosPerfilService,
    private alertService:AlertService
  ) {
    this.perfilForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]+$'), Validators.minLength(4), Validators.maxLength(20)]],
      apellido: ['', [Validators.required, Validators.pattern('^[a-zA-Z\\s]+$'), Validators.minLength(4), Validators.maxLength(20)]],
      fecNac: ['', [Validators.required, this.ageValidator(18)]],
      cuit: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(10), Validators.maxLength(13)]],
      provincia: ['', Validators.required],
      localidad: ['', Validators.required],
      direccion: ['', Validators.required],
      tel1: ['', [Validators.required, Validators.pattern('^[0-9]+$'), Validators.minLength(7), Validators.maxLength(15)]],
      tel2: ['', [Validators.pattern('^[0-9]+$'), Validators.minLength(7), Validators.maxLength(15)]]
    });
  }

  ngOnInit(): void {
    this.cargarDatosPerfil();
  }

  async cargarDatosPerfil(): Promise<void> {
    try {
      const datos = await this.datosPerfilService.obtenerDatosPerfil();
      if (datos) {
        this.perfilForm.setValue({
          nombre: datos.nombre,
          apellido: datos.apellido,
          fecNac: datos.fecNac,
          cuit: datos.cuit,
          provincia: datos.provincia,
          localidad: datos.localidad,
          direccion: datos.direccion,
          tel1: datos.tel1,
          tel2: datos.tel2 || ''
        });
      }
    } catch (error) {
      this.alertService.showConfirmMessageError('Error al cargar los datos del perfil');
    } finally {
      this.loading = false;  
    }
  }

  async guardarDatos(): Promise<void> {
    if (this.perfilForm.valid) {
      const datos: Datos = this.perfilForm.value;
      // Aplicar trim y capitalización de la primera letra
      datos.nombre = datos.nombre.trim().charAt(0).toUpperCase() + datos.nombre.trim().slice(1).toLowerCase();
      datos.apellido = datos.apellido.trim().charAt(0).toUpperCase() + datos.apellido.trim().slice(1).toLowerCase();
      datos.direccion = datos.direccion.trim().charAt(0).toUpperCase() + datos.direccion.trim().slice(1).toLowerCase();
      try {
        await this.datosPerfilService.guardarDatosPerfil(datos);
        this.alertService.showConfirmMessageSuccess('Datos guardados exitosamente');
      } catch (error) {
        this.alertService.showConfirmMessageError('Error al guardar los datos');
      }
    } else {
      this.alertService.showConfirmMessageError('El formulario es inválido');
    }
  }
  ageValidator(minAge: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= minAge ? null : { ageInvalid: true };
    };
  }
}
