<div class="container mt-2">
    <div class="row">
      <div class="col-md-6">
        <form [formGroup]="cambioPassForm" (ngSubmit)="onChangePass()">
          <label for="actual-pass">Ingrese su contraseña actual:</label>
          <input type="password" id="actual-pass" formControlName="actualPass" class="form-control mb-3">
  
          <label for="new-pass">Ingrese su nueva contraseña:</label>
          <input type="password" id="new-pass" formControlName="newPass" class="form-control mb-3">
  
          <label for="confirm-new-pass">Confirme su nueva contraseña:</label>
          <input type="password" id="confirm-new-pass" formControlName="confirmNewPass" class="form-control mb-3">
  
          <button type="submit" class="btn d-flex align-items-center" [disabled]="cambioPassForm.invalid">
            <mat-icon>save</mat-icon>
            <span class="ms-2">Guardar</span>
          </button>
        </form>
      </div>
    </div>
  </div>
  