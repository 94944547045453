<div *ngIf="loading" class="text-center" style="margin-top: 8rem;">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Cargando...</p>
</div>

<div class="container mt-2" *ngIf="!loading">
  <div class="d-flex justify-content-start mb-4">
    <button class="btn btn-dark me-2" [class.active]="isGridView" (click)="toggleView('grid')"
      matTooltip="Vista Cuadricula" matTooltipShowDelay="500" matTooltipPosition="above">
      <mat-icon>grid_on</mat-icon>
    </button>
    <button class="btn btn-dark" [class.active]="!isGridView" (click)="toggleView('list')" matTooltip="Vista Lista"
      matTooltipShowDelay="500" matTooltipPosition="above">
      <mat-icon>list</mat-icon>
    </button>
  </div>

  <div class="row" *ngIf="productos.length === 0">
    <div class="col-12 text-center my-5">
      <h1>No hay elementos añadidos</h1>
    </div>
  </div>

  <!-- Vista de productos -->
  <div *ngIf="isGridView && productos.length > 0" class="row mb-5">
    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3 mb-4" *ngFor="let producto of productos">
        <div class="card">
          <img [src]="imagesUrls[producto.codigo] || 'assets/noimagen.png'" class="card-img-top">
          <div class="card-body">
            <div class="d-flex justify-content-between mb-0" style="margin-top: -10px;">
              <h5 class="card-title" style="margin-top: -5px; font-style: italic;">Marca</h5>
              <h5 class="card-title" style="margin-top: -5px; font-style: italic;">Precio</h5>
            </div>
            <div class="d-flex justify-content-between mt-0">
              <h5 class="card-title" style="margin-top: -17px; font-size: 14px;font-weight: bold;">{{ producto.marca }}
              </h5>
              <h5 class="card-title" style="margin-top: -17px; font-size: 14px;font-weight: 550;">{{ producto.precio |
                currency:'USD':'symbol' }}</h5>
            </div>
            <p class="card-text" style="font-weight: 550;">{{ producto.descripcion }}</p>
            <div class="d-flex justify-content-between align-items-center">
              <button class="btn btn-dark" (click)="removerProducto(producto.codigo)" matTooltip="Quitar un producto"
                matTooltipShowDelay="500" matTooltipPosition="above">
                <mat-icon>remove_shopping_cart</mat-icon>
              </button>
              <div class="d-flex flex-column">
                <p class="mb-0">Cantidad: {{ producto.cantidad }}</p>
                <h5 class="card-title mb-0" style="font-weight: bold;">Cod: {{ producto.codigo }}</h5>
              </div>
              <button class="btn btn-danger" (click)="eliminarProducto(producto.codigo)"
                matTooltip="Quitar todos los productos" matTooltipShowDelay="500" matTooltipPosition="above">
                <mat-icon>clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- List view -->
  <div *ngIf="!isGridView && productos.length > 0" class="table-responsive mb-5">
    <table class="table table-striped table-hover">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Código</th>
          <th scope="col">Descripción</th>
          <th scope="col">Precio</th>
          <th scope="col">Cantidad</th>
          <th scope="col">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let producto of productos">
          <td>{{ producto.codigo }}</td>
          <td>{{ producto.descripcion }}</td>
          <td>{{ producto.precio | currency:'USD':'symbol' }}</td>
          <td>{{ producto.cantidad }}</td>
          <td>
            <button class="btn btn-dark me-2" (click)="removerProducto(producto.codigo)" matTooltip="Quitar un producto"
              matTooltipShowDelay="500" matTooltipPosition="above">
              <mat-icon>remove_shopping_cart</mat-icon>
            </button>
            <button class="btn btn-danger" (click)="eliminarProducto(producto.codigo)"
              matTooltip="Quitar todos los productos" matTooltipShowDelay="500" matTooltipPosition="above">
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Total and Buttons -->
  <div class="bg-dark text-white p-3 rounded shadow position-fixed"
    style="bottom: 50px; right: 20px; margin-right: 75px; font-size: 25px;">
    <p class="mb-0">Total: ${{ total | number:'1.2-2' }}</p>
  </div>

  <button *ngIf="productos.length > 0" class="btn btn-success position-fixed" style="bottom: 20px; right: 20px;"
    (click)="solicitarCotizacion()" matTooltip="Pedir/Comprar" matTooltipShowDelay="500" matTooltipPosition="before">
    <mat-icon>request_quote</mat-icon>
  </button>

  <button *ngIf="productos.length > 0" class="btn btn-danger position-fixed"
    style="bottom: 20px; right: 20px; margin-bottom: 60px;" (click)="vaciarCarrito()" matTooltip="Vaciar carrito"
    matTooltipShowDelay="500" matTooltipPosition="before">
    <mat-icon>delete</mat-icon>
  </button>
  
</div>
<div class="d-block d-md-none" style="height: 2px;"></div>