// pedidos.component.ts
import { Component, OnInit } from '@angular/core';
import { CarritoService } from '../carrito.service';
import { AlertService } from '../alert.service';
import { ImageService } from '../image.service';
import emailjs from 'emailjs-com';
import { LoginService } from '../login/login.service';
import { DatosPerfilService } from '../perfil/datos.perfil.service';
import { CatalogoService } from '../catalogo/catalogo.service';
import { Producto } from '../catalogo/producto.interface';
type EmailParams = {
  from_fecha:string;
  from_name: string;
  from_cuit: string;
  from_email: string | null;
  from_provincia: string;
  from_localidad: string;
  from_address: string;
  from_phone: string;
  from_phone2: string;
  total: string;
  [key: string]: string | null; // Permite agregar propiedades dinámicas
};
@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css'],
})
export class PedidosComponent implements OnInit {
  productos: any[] = [];
  total: number = 0;
  isGridView = true;
  loading = true;
  tieneDatos: boolean = false;
  imagesUrls: { [key: string]: string } = {};
  toggleView(view: string): void {
    this.isGridView = view === 'grid';
  }
  constructor(
    private carritoService: CarritoService,
    private imageService: ImageService,
    private alertService: AlertService,
    private loginService: LoginService,
    private datosPerfilService: DatosPerfilService,
    private catalogoService:CatalogoService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.verificarDatosPerfil();
  }
  private async verificarDatosPerfil(): Promise<void> {
    this.loading = true;
    const datosPerfil = await this.datosPerfilService.obtenerDatosPerfil();
    this.tieneDatos = datosPerfil !== null;
    if (this.tieneDatos == false) this.loading = true;
    if (this.tieneDatos == true) {
      this.loading = false;
      this.cargarProductos();
    }
  }
  private formatCurrency(value: number): string {
    return value.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
    });
  }
  loadImagesForProductos() {
    this.productos.forEach((producto) => {
      this.loadImageUrl(producto.codigo);
    });
  }

  loadImageUrl(codigo: string) {
    this.imageService.getImageUrlByCode(codigo).subscribe(
      (url) => {
        this.imagesUrls[codigo] = url;
      },
      (error) => {
        if (error.code === 'storage/object-not-found') {
        }
      }
    );
  }
  async cargarProductos() {
    this.loading = true;
    try {
        await new Promise<void>((resolve, reject) => {
            this.carritoService.obtenerProductos().subscribe(
                async (productos) => {
                    const productosFiltrados : Producto[] = [];

                    // Paralelizar las comprobaciones de stock
                    const checkStockPromises = productos.map(async (producto) => {
                        const hayStock = await this.catalogoService.isProductInStock(producto.codigo).toPromise();

                        if (hayStock) {
                            productosFiltrados.push(producto); // Mantén el producto si hay stock
                        } else {
                            await this.removerProductoSinStock(producto.codigo); // Elimina el producto si no hay stock
                        }
                    });

                    // Esperar a que todas las comprobaciones de stock se completen
                    await Promise.all(checkStockPromises);

                    this.productos = productosFiltrados;
                    this.calcularTotal();
                    resolve();
                },
                (error) => {
                    console.error('Error al obtener productos del carrito:', error);
                    reject(error);
                }
            );
        });

        // Cargar imágenes en paralelo solo para productos en stock
        this.loadImagesForProductos();
    } catch (error) {
        console.error('Error en cargarProductos:', error);
    } finally {
        this.loading = false;
    }
}
  calcularTotal() {
    this.total = this.productos.reduce(
      (acc, producto) => acc + producto.precio * producto.cantidad, 0);
  }

  async removerProducto(codigo: string): Promise<void> {
    this.alertService.showLoadingMessage();
    try {
      await this.carritoService.removerProducto(codigo);
      this.cargarProductos();
      this.alertService.showErrorMessage('Unidad quitada del carrito');
    } catch (error) {
      console.error('Error al remover producto del carrito:', error);
      this.alertService.showErrorMessage('Error al quitar unidad del carrito');
    }
  }
  async removerProductoSinStock(codigo: string): Promise<void> {
    try {
      await this.carritoService.removerProducto(codigo);
      this.alertService.showErrorMessage('Uno o mas productos fueron quitados debido a falta de stock.')
      this.cargarProductos();
    } catch (error) {
    }
  }

  async eliminarProducto(codigo: string): Promise<void> {
    this.alertService.showLoadingMessage();
    try {
      await this.carritoService.eliminarProducto(codigo);
      this.cargarProductos();
      this.alertService.showErrorMessage('Productos borrados del carrito');
    } catch (error) {
      console.error('Error al eliminar producto del carrito:', error);
      this.alertService.showErrorMessage('Error al borrar productos del carrito');
    }
  }
  async vaciarCarrito(): Promise<void> {
    try {
      // Mostrar alerta de confirmación
      const confirmMessage = '¿Está seguro de que desea vaciar el carrito?';
      const confirmResponse = await this.alertService.showYesNoMessage(
        confirmMessage,
        'Carrito Vacio'
      );
      // Verificar si el usuario confirmó la acción
      if (confirmResponse) {
        await this.carritoService.vaciarCarrito();
        this.alertService.showConfirmMessageSuccess('Carrito Vacio');
      }
    } catch (error) {
      this.alertService.showErrorMessage('Error al vaciar carrito');
    }
  }

  async solicitarCotizacion() {
    if (!this.tieneDatos) {
      this.alertService.showErrorMessage('Complete sus Datos en Perfil');
      return;
    }
    const confirmResponse = await this.alertService.showYesNoMessage(
      '¿Está seguro de que desea solicitar una cotización?');
    if (!confirmResponse) return;
    
    this.alertService.showLoadingMessage();
    const userEmail = this.loginService.getEmail();
    const numeroPedido = Date.now().toString();
    // Obtener los datos del perfil
    const datosPerfil = await this.datosPerfilService.obtenerDatosPerfil();
    // Rellenar la información del cliente
    const fromFecha = new Date();
    const fromName = datosPerfil?.apellido + ' ' + datosPerfil?.nombre || 'Nombre no disponible';
    const fromCuit = datosPerfil?.cuit || 'Cuit/Cuil no disponible';
    const fromProvincia = datosPerfil?.provincia || 'Provincia no disponible';
    const fromLocalidad = datosPerfil?.localidad || 'Localidad no disponible';
    const fromAddress = datosPerfil?.direccion || 'Dirección no disponible';
    const fromPhone = datosPerfil?.tel1 || 'Teléfono no disponible';
    const fromPhone2 = datosPerfil?.tel2 || '';
    const fromPedido = numeroPedido;
    // Inicializar emailParams con los campos estáticos
    const emailParams: EmailParams = {
      from_fecha: this.formatDate(fromFecha),
      from_name: fromName,
      from_cuit: fromCuit,
      from_email: userEmail,
      from_pedido: fromPedido,
      from_provincia: fromProvincia,
      from_localidad: fromLocalidad,
      from_address: fromAddress,
      from_phone: fromPhone,
      from_phone2: fromPhone2,
      total: this.formatCurrency(this.total),
    };

    // Añadir dinámicamente los productos
    this.productos.forEach((product, index) => {
      emailParams[`product_code_${index + 1}`] = product.codigo;
      emailParams[`product_description_${index + 1}`] = product.descripcion;
      emailParams[`product_price_${index + 1}`] = this.formatCurrency(product.precio );
      emailParams[`product_quantity_${index + 1}`] = product.cantidad.toString();
    });
    try {
      await emailjs.send('service_w3xgs85','template_iny0xo9',emailParams,'Rn9Ae7ngVGW42hzRi');
      this.alertService.showConfirmMessageSuccess('Cotización enviada.');
    } catch (error) {
      this.alertService.showErrorMessage('Hubo un error al enviar la cotización' + error );
    }
    // solicitamos la cotización para manejar el carrito
    try {
      await this.carritoService.solicitarCotizacion(numeroPedido, this.total);
    } catch (error) {
      this.alertService.showErrorMessage('Hubo un error al manejar el carrito' + error);
      return;
    }
  }
  // Función para formatear la fecha
  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
}
