<div class="login-container d-flex justify-content-center align-items-center vh-100">
  <div class="login-box p-4 shadow rounded">
    <div class="text-center mb-4">
      <h1>Registrarse</h1>
    </div>
    <form #form="ngForm" (ngSubmit)="registrar(form)">
      <div class="form-group row mb-3">
        <label for="email" class="col-sm-4 col-form-label text-start">Correo Electrónico:</label>
        <div class="col-sm-8">
          <input type="email" id="email" name="email" class="form-control" ngModel required email>
          <div *ngIf="form.submitted || form.controls['email']?.touched">
            <div *ngIf="form.controls['email']?.errors?.['required']" class="text-danger">
              El correo electrónico es obligatorio.
            </div>
            <div *ngIf="form.controls['email']?.errors?.['email']" class="text-danger">
              El correo electrónico debe ser válido.
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-3">
        <label for="password" class="col-sm-4 col-form-label text-start">Contraseña:</label>
        <div class="col-sm-8">
          <input type="password" id="password" name="password" class="form-control" ngModel required minlength="6">
          <div *ngIf="form.submitted || form.controls['password']?.touched">
            <div *ngIf="form.controls['password']?.errors?.['required']" class="text-danger">
              La contraseña es obligatoria.
            </div>
            <div *ngIf="form.controls['password']?.errors?.['minlength']" class="text-danger">
              La contraseña debe tener al menos 6 caracteres.
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="signup-button" [disabled]="form.invalid">Registrar</button>
        <button type="button" class="back-button" (click)="volver()">Cancelar</button>
      </div>
    </form>
  </div>
</div>