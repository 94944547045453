<div *ngIf="loading" class="text-center" style="margin-top: 8rem;">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Cargando...</p>
</div>
<div *ngIf="!loading" class="container">
  <div class="row">
    <div class="col-12 col-md-6"> <!-- columna izquierda, 12 columnas en móvil, 6 en escritorio -->
      <form [formGroup]="bannerForm" style="background-color: rgba(212, 145, 145, 0.76); padding: 15px;">
        <div class="mb-3">
          <h1 class="h4">Actualizar anuncios del banner</h1>
          <label for="banner1">Anuncio 1</label>
          <input type="text" id="banner1" name="banner1" class="form-control" formControlName="banner1">
        </div>
        <div class="mb-3">
          <label for="banner2">Anuncio 2</label>
          <input type="text" id="banner2" name="banner2" class="form-control" formControlName="banner2">
        </div>
        <div class="mb-3">
          <label for="banner3">Anuncio 3</label>
          <input type="text" id="banner3" name="banner3" class="form-control" formControlName="banner3">
        </div>
        <button type="button" class="btn btn-primary d-flex align-items-center mb-2" (click)="actualizarBanner()">
          <mat-icon>save</mat-icon>
          <span class="ms-2">Actualizar Banner</span>
        </button>
      </form>
      <div class="mt-3" style="background-color: rgba(175, 156, 132, 0.856); padding: 15px;">
        <div class="my-1">
          <h1 class="h4">Actualizar base de datos</h1>
          <label for="base">Base de datos (.csv):</label>
          <input type="file" id="base" name="base" class="form-control mt-1" accept=".csv"
            (change)="onFileSelected($event)">
        </div>
        <button type="button" class="btn btn-primary d-flex align-items-center mt-1" (click)="actualizarCsv()">
          <mat-icon>save</mat-icon>
          <span class="ms-2">Actualizar CSV</span>
        </button>
        <div class="my-2">
          <label for="imagenes">Agregar Imagenes a la base de datos:</label>
          <input type="file" id="imagenes" name="imagenes" class="form-control mt-1" accept="image/*" multiple
            (change)="onFileSelected($event)">
        </div>
        <button type="button" class="btn btn-primary d-flex align-items-center" (click)="agregarImagenes()">
          <mat-icon>save</mat-icon>
          <span class="ms-2">Agregar Imagenes</span>
        </button>
        <div class="my-2">
          <label for="deleteImagen">Eliminar Imagen de la base de datos(Codigo):</label>
          <input type="text" id="deleteImagen" name="deleteImagen" class="form-control mt-1" [(ngModel)]="codigoImagen">
        </div>
        <button type="button" class="btn btn-primary d-flex align-items-center" (click)="borrarImagen()">
          <mat-icon>save</mat-icon>
          <span class="ms-2">Eliminar Imagen</span>
        </button>
      </div>
      <div class="p-3 mt-3" style="background-color: rgba(132, 141, 101, 0.89);">
        <div class="mb-3">
          <h1 class="h4">Habilitar/Deshabilitar Email para Registro</h1>
          <label class="mb-2" for="email">Email a habilitar</label>
          <div class="input-group">
            <input type="text" id="email" class="form-control" [(ngModel)]="email">
          </div>
        </div>
        <div class="d-flex justify-content-between"> <!-- Nueva fila para los botones -->
          <button type="button" class="btn btn-success d-flex align-items-center" (click)="habilitarEmail()">
            <span class="ms-2">Habilitar Email</span>
          </button>
        </div>
        <button class="btn btn-dark my-2" (click)="toggleVerEmails()">
          <mat-icon>visibility</mat-icon>
        </button>
        <ul *ngIf="!loading && verEmails">
          <li *ngFor="let cEmail of clientesEmails">
            {{ cEmail }}
            <button (click)="deshabilitarEmail(cEmail)">Eliminar</button>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-md-6 mt-4 mt-md-0"> <!-- columna derecha, con margen superior en móvil -->
      <form [formGroup]="stockForm" class="p-3" style="background-color: rgba(118, 170, 170, 0.89);">
        <div class="mb-3">
          <h1 class="h4">Actualizar el estado de stock</h1>
          <label class="mb-2" for="producto">Codigo de Producto</label>
          <div class="input-group">
            <input type="text" id="producto" name="producto" class="form-control" [formControl]="productoControl"
              (keyup.enter)="comprobarEstadoProducto()" [matAutocomplete]="autoCodigo">
            <mat-autocomplete #autoCodigo="matAutocomplete">
              <mat-option *ngFor="let codigo of filteredCodigos | async" [value]="codigo">
                {{ codigo }}
              </mat-option>
            </mat-autocomplete>
            <button class="btn btn-secondary" type="button" (click)="comprobarEstadoProducto()">
              <mat-icon *ngIf="!loadingEstado">search</mat-icon>
              <div *ngIf="loadingEstado" class="spinner-border spinner-border-sm " role="status"></div>
            </button>
          </div>
        </div>
        <h3 class="h5 mt-3" [ngClass]="{'text-success': estado === true, 'text-danger': estado === false}">
          Estado de producto:
          {{ estado === null ? '' : (estado ? 'En Stock' : 'Sin Stock') }}
        </h3>
        <div class="d-flex justify-content-between"> <!-- Nueva fila para los botones -->
          <button type="button" class="btn btn-success d-flex align-items-center"
            [disabled]="estado === true || estado === null" (click)="productoConStock()">
            <span class="ms-2">Actualizar Stock</span>
          </button>
          <button type="button" class="btn btn-danger d-flex align-items-center ms-2"
            [disabled]="estado === false || estado === null" (click)="productoSinStock()">
            <span class="ms-2">Actualizar Sin Stock</span>
          </button>
        </div>
      </form>
      <!-- factura -->
      <form [formGroup]="facturaForm" class="p-3 mt-4" style="background-color: rgb(129, 172, 134);">
        <div class="mb-3">
          <h1 class="h4">Actualizar el estado de factura</h1>
          <label class="my-2" for="facturaEmail">Email de cliente</label>
          <div class="input-group">
            <input type="text" id="facturaEmail" name="facturaEmail" class="form-control"
              [formControl]="facturaEmailControl" [matAutocomplete]="autoEmails" 
              (keyup.enter)="cargarPedidosPorEmail(facturaEmailControl)">            

            <mat-autocomplete #autoEmails="matAutocomplete">
              <mat-option *ngFor="let email of filteredEmails" [value]="email">
                {{ email }}
              </mat-option>
            </mat-autocomplete>
            <button class="btn btn-secondary" type="button" (click)="cargarPedidosPorEmail(facturaEmailControl)">
              <mat-icon *ngIf="!loadingPedidos">search</mat-icon>
              <div *ngIf="loadingPedidos" class="spinner-border spinner-border-sm" role="status"></div>
            </button>
          </div>
          <label class="my-2" for="factura">N° de Pedido</label>
          <div class="input-group">
            <input type="text" id="factura" name="factura" class="form-control" [formControl]="facturaControl"
              [matAutocomplete]="autoPedidos" (keyup.enter)="comprobarEstadoFactura(facturaControl)">
              <mat-autocomplete #autoPedidos="matAutocomplete">
                <mat-option *ngFor="let pedido of numerosDePedido" [value]="pedido">
                  {{ pedido }}
                </mat-option>
              </mat-autocomplete>
            <button class="btn btn-secondary" type="button" (click)="comprobarEstadoFactura(facturaControl)" [attr.disabled]="facturaControl.disabled ? true : null">
              <mat-icon *ngIf="!loadingFactura">search</mat-icon>
              <div *ngIf="loadingFactura" class="spinner-border spinner-border-sm" role="status"></div>
            </button>
            <button [disabled]="!facturaCargada" class="btn btn-primary ms-2" (click)="abrirModal()"
             [ngClass]="{'btn-success': facturaCargada,'btn-danger': !facturaCargada}"
              matTooltip="Ver Detalle">
              <mat-icon>visibility</mat-icon>
            </button>
          </div>

          <!-- Campo para la fecha -->
          <label class="mb-2 mt-3" for="fecha">Fecha de Factura</label>
          <input type="text" id="fecha" name="fecha" class="form-control" formControlName="fecha" readonly>
        </div>

        <div class="d-flex flex-column">
         <!-- Iva y Descuento en la misma fila -->
          <div class="row my-2">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label for="iva" class="form-label">Iva(%):</label>
              <input type="text" id="iva" name="iva" class="form-control form-control-sm" formControlName="iva">
            </div>
            <div class="col-12 col-md-6">
              <label for="descuento" class="form-label">Descuento(%):</label>
              <input type="text" id="descuento" name="descuento" class="form-control form-control-sm" formControlName="descuento">
            </div>
          </div>

          <!-- SubTotal -->
          <div>
            <label for="subtotal" class="form-label" style="font-size: 17px; font-weight: 400;">SubTotal:</label>
            <input type="text" id="subtotal" class="form-control" [value]="facturaForm.get('subtotal')?.value" readonly>
          </div>

          <!-- Total -->
          <div class="mt-1">
            <label for="total" class="form-label" style="font-size: 17px; font-weight: 400;">Total:</label>
            <input type="text" id="total" class="form-control" [value]="facturaForm.get('total')?.value" readonly>
          </div>
        </div>
        <!-- Estado de la factura -->
        <div id="estadoFactura"
              [ngClass]="{
                'alert-secondary': !facturaForm.get('estado')?.value || facturaForm.get('estado')?.value === 'Estado no disponible',
                'alert-warning': facturaForm.get('estado')?.value === 'Pendiente',
                'alert-info': facturaForm.get('estado')?.value === 'Aprobada',
                'alert-danger': facturaForm.get('estado')?.value === 'Cancelada'
              }"
              class="alert mt-2"
              role="alert">
          {{ facturaForm.get('estado')?.value || 'Estado no disponible' }}
        </div>

        <div class="d-flex justify-content-between mt-3"> <!-- Nueva fila para los botones -->
          <button type="button" class="btn btn-success d-flex align-items-center"
          [disabled]="facturaForm.get('estado')?.value === '' || facturaForm.get('estado')?.value === 'Aprobada'"
          (click)="aprobarFactura()">
            <span class="ms-2">Aprobar Factura</span>
          </button>
          <button type="button" class="btn btn-warning d-flex align-items-center"
          [disabled]="facturaForm.get('estado')?.value === '' || facturaForm.get('estado')?.value === 'Pendiente'"
          (click)="pendienteFactura()">
            <span class="ms-2">Regresar a Pendiente</span>
          </button>
          <button type="button" class="btn btn-danger d-flex align-items-center ms-2"
          [disabled]="facturaForm.get('estado')?.value === '' || facturaForm.get('estado')?.value === 'Cancelada'"
          (click)="cancelarFactura()">
            <span class="ms-2">Cancelar Factura</span>
          </button>
        </div>
      </form>


      <!-- Modal -->
      <div class="modal" tabindex="-1" role="dialog" *ngIf="mostrarModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <!-- Contenedor scrollable para la tabla -->
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Codigo</th>
                      <th scope="col">Producto</th>
                      <th scope="col">Marca</th>
                      <th scope="col">Cantidad</th>
                      <th scope="col">Precio</th>
                      <th scope="col">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let detalle of detalles$ | async">
                      <td>{{ detalle.codigo }}</td>
                      <td>{{ detalle.descripcion }}</td>
                      <td>{{ detalle.marca }}</td>
                      <td>{{ detalle.cantidad }}</td>
                      <td>{{ detalle.precio | currency:'USD':'symbol'}}</td>
                      <td>{{ (detalle.cantidad * detalle.precio) | currency:'USD':'symbol'}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" (click)="cerrarModal()">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>