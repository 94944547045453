import { Injectable } from '@angular/core';
import { LoginService } from '../login/login.service';
import { Firestore,doc,getDoc,setDoc } from '@angular/fire/firestore';
import { Datos } from './datos.interface';
import { AlertService } from '../alert.service';

@Injectable({
  providedIn: 'root'
})
export class DatosPerfilService {

  constructor(private firestore: Firestore,
              private loginService: LoginService,
              private alertService:AlertService) { }

  async getApYNomOrEmail(): Promise<string | null> {
    const emailUsuario = this.loginService.getEmail();

      if (!emailUsuario) { return null; }
  
      const docRef = doc(this.firestore, 'datos', emailUsuario);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data() as Datos;
        if (data.nombre && data.apellido) {
          return `${data.nombre} ${data.apellido}`;
        }
      }
      return emailUsuario;
  }
  async obtenerDatosPerfil(): Promise<Datos | null> {
    const emailUsuario = this.loginService.getEmail();
    if (!emailUsuario) { return null; }

    const docRef = doc(this.firestore, 'datos', emailUsuario);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) { return docSnap.data() as Datos; } 
    else { return null; }
  }
  async guardarDatosPerfil(datos: Datos): Promise<void> {
    const emailUsuario = this.loginService.getEmail();

    if (!emailUsuario) {return;}

    const docRef = doc(this.firestore, 'datos', emailUsuario);
    await setDoc(docRef, datos);
    this.alertService.showSuccessMessage('Datos del perfil guardados correctamente');
  }
}
