import { Injectable } from '@angular/core';
import { LoginService } from './login/login.service';
import { Producto } from './catalogo/producto.interface';
import { Firestore, getDocs, collection, collectionData, updateDoc, deleteDoc, getDoc, setDoc, doc, increment} from '@angular/fire/firestore';
import { map, Observable } from 'rxjs';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class CarritoService {
  productos: Producto[] = [];

  constructor(private firestore: Firestore, private loginService: LoginService, private alertService:AlertService
  ) {}
async addCarrito(producto: Producto) {
  try {
      this.alertService.showLoadingMessage();
      const emailUsuario = this.loginService.getEmail();
      if (!emailUsuario) {
          console.error('Usuario no autenticado');
          return;
      }

      const carritoRef = collection(this.firestore, 'carritos');
      const userCarritoDocRef = doc(carritoRef, emailUsuario);
      const productosRef = collection(userCarritoDocRef, 'productos');
      const productoRef = doc(productosRef, producto.codigo);

      // Verificar si el producto ya existe en el carrito
      const docSnap = await getDoc(productoRef);

      if (docSnap.exists()) {
          // Si el producto ya existe, incrementar su cantidad
          await setDoc(
              productoRef,
              { cantidad: increment(producto.cantidad) },
              { merge: true }
          );
          this.alertService.showSuccessMessage('Producto/s añadido/s al carrito.');
      } else {
          // Si el producto no existe, contar el número de productos en el carrito
          const productosSnapshot = await getDocs(productosRef);
          const totalProductos = productosSnapshot.size;

          if (totalProductos >= 30) {
              this.alertService.showErrorMessage('Carrito lleno, límite de 30 productos alcanzado.');
              return; // Salir del método si se ha alcanzado el límite
          }

          // Verificar y agregar el campo `email` en el documento del carrito del usuario
          const userCarritoDocSnap = await getDoc(userCarritoDocRef);
          if (!userCarritoDocSnap.exists()) {
              // Si el documento del carrito no existe, créalo con el campo `email`
              await setDoc(userCarritoDocRef, { email: emailUsuario });
          } else if (!userCarritoDocSnap.data()?.['email']) {
              // Si el documento del carrito existe pero no tiene el campo `email`, actualízalo
              await setDoc(
                  userCarritoDocRef,
                  { email: emailUsuario },
                  { merge: true }
              );
          }

          // Añadir el nuevo producto al carrito
          await setDoc(productoRef, { ...producto, cantidad: producto.cantidad });
          this.alertService.showSuccessMessage('Producto/s añadido/s al carrito.');
      }
  } catch (error) {
      this.alertService.showErrorMessage('Error al agregar producto al carrito: ' + error);
  }
}

  obtenerProductos(): Observable<Producto[]> {
    const emailUsuario = this.loginService.getEmail();
    if (!emailUsuario) {
      console.error('Usuario no autenticado');
      return new Observable<Producto[]>((subscriber) => subscriber.next([]));
    }

    const carritoRef = collection(
      this.firestore,
      'carritos',
      emailUsuario,
      'productos'
    );
    return collectionData(carritoRef, { idField: 'codigo' }).pipe(
      map((docs) => docs as Producto[])
    );
  }

  async removerProducto(codigo: string): Promise<void> {
    const emailUsuario = this.loginService.getEmail();
    if (!emailUsuario) {
      console.error('Usuario no autenticado');
      return;
    }

    const productoRef = doc(this.firestore, 'carritos',  emailUsuario, 'productos', codigo);
    const docSnap = await getDoc(productoRef);

    if (docSnap.exists()) {
      const producto = docSnap.data() as Producto;
      if (producto.cantidad > 1) {
        await updateDoc(productoRef, { cantidad: producto.cantidad - 1 });
      } else {
        await deleteDoc(productoRef);
      }
      console.log('Producto actualizado en el carrito');
    } else {
      console.error('El producto no existe en el carrito');
    }
  }

  async eliminarProducto(codigo: string): Promise<void> {
    const emailUsuario = this.loginService.getEmail();
    if (!emailUsuario) {
      console.error('Usuario no autenticado');
      return;
    }

    const productoRef = doc(
      this.firestore,
      'carritos',
      emailUsuario,
      'productos',
      codigo
    );
    await deleteDoc(productoRef);
    console.log('Producto eliminado del carrito');
  }
  async vaciarCarrito(): Promise<void> {
    try {
      const emailUsuario = this.loginService.getEmail();
      if (!emailUsuario) {
        console.error('Usuario no autenticado');
        return;
      }

      const productosRef = collection(
        this.firestore,
        'carritos',
        emailUsuario,
        'productos'
      );
      const querySnapshot = await getDocs(productosRef);

      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      console.log('Carrito vaciado correctamente');
    } catch (error) {
      console.error('Error al vaciar el carrito:', error);
    }
  }
  allItemsCarrito(): Observable<number> {
    return this.obtenerProductos().pipe(
      map((productos: Producto[]) =>
        productos.reduce((total, producto) => total + producto.cantidad, 0)
      )
    );
  }
  formatCurrency(amount: number): string {
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
    }).format(amount);
  }
  async solicitarCotizacion(nPedido:string,total: number,subtotal?: number,iva?: number, descuento?: number): Promise<void> {
    const userEmail = this.loginService.getEmail();
    if (!userEmail) {
      throw new Error('El usuario no está autenticado.');
    }
    const carritoRef = collection(
      this.firestore,
      `carritos/${userEmail}/productos`
    );
    try {
      const productosSnapshot = await getDocs(carritoRef);

      const numeroDePedido = nPedido;
      const totalFormateado = this.formatCurrency(total);
      const subtotalFormateado = this.formatCurrency(subtotal ?? total);
      const ivaAplicado = iva || 0;
      const descuentoAplicado = descuento || 0;

      const detalleData = {
        fecha: new Date(),
        iva: ivaAplicado,
        descuento: descuentoAplicado,
        subtotal: subtotalFormateado,
        total: totalFormateado,
        numeroDePedido,
        estado: 'Pendiente',
        userEmail, // Agregar el email del usuario si es necesario
      };
      const detalleRef = doc(
        this.firestore,
        `facturas/pendientes/${userEmail}/${numeroDePedido}`
      );
      await setDoc(detalleRef, detalleData);

      // Copiar cada producto a la nueva colección
      for (const docSnapshot of productosSnapshot.docs) {
        const productoData = docSnapshot.data();
        const productoPendienteRef = doc(
          this.firestore,
          `facturas/pendientes/${userEmail}/${numeroDePedido}/detalle`,
          docSnapshot.id
        );
        await setDoc(productoPendienteRef, productoData);
      }
      await this.vaciarCarrito();
    } catch (error) {
      console.error('Error al solicitar cotización:', error);
      throw error;
    }
  }
}
