<div class="login-container d-flex justify-content-center align-items-center vh-100">
    <div class="login-box p-4 col-md-4 col-sm-9 shadow rounded">
        <div class="text-center mb-4">
            <h1>Recuperar Contraseña</h1>
        </div>
        <div class="text-center mb-4">
            <h3>Por favor, ingrese su correo y presione el botón para recibir su nueva contraseña</h3>
        </div>
        <form [formGroup]="recuperarForm">
            <div class="form-group row mb-3">
                <label for="email" class="col-sm-4 col-form-label text-black">Correo Electrónico:</label>
                <div class="col-sm-8">
                    <input type="email" class="form-control" formControlName="email">
                </div>
            </div>
            <div class="text-center">
                <button type="button" class="send-button" (click)="enviar()">Enviar Contraseña</button>
                <button type="button" class="back-button" routerLink="/login">Volver</button>
            </div>
        </form>
    </div>
</div>
