<div *ngIf="loading" class="text-center" style="margin-top: 8rem;">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Cargando...</p>
</div>
<div class="container mt-2">
  <div class="row">
    <div class="col-md-6">
      <form [formGroup]="perfilForm" *ngIf="!loading">
        <div class="mb-3">
          <label for="nombre">Nombre:</label>
          <input type="text" id="nombre" name="nombre" class="form-control" formControlName="nombre">
          <div *ngIf="perfilForm.get('nombre')?.invalid && perfilForm.get('nombre')?.touched" class="text-danger">
            Nombre debe tener entre 4 y 20 caracteres y solo contener letras y espacios.
          </div>
        </div>

        <div class="mb-3">
          <label for="apellido">Apellido:</label>
          <input type="text" id="apellido" name="apellido" class="form-control" formControlName="apellido">
          <div *ngIf="perfilForm.get('apellido')?.invalid && perfilForm.get('apellido')?.touched" class="text-danger">
            Apellido debe tener entre 4 y 20 caracteres y solo contener letras y espacios.
          </div>
        </div>

        <div class="mb-3">
          <label for="fechaNacimiento">Fecha de nacimiento:</label>
          <input type="date" id="fechaNacimiento" name="fechaNacimiento" class="form-control" formControlName="fecNac">
          <div *ngIf="perfilForm.get('fecNac')?.invalid && perfilForm.get('fecNac')?.touched" class="text-danger">
            Debe tener al menos 18 años.
          </div>
        </div>

        <div class="mb-3">
          <label for="cuitCuil">CUIT o CUIL:</label>
          <input type="text" id="cuitCuil" name="cuitCuil" class="form-control" formControlName="cuit">
          <div *ngIf="perfilForm.get('cuit')?.invalid && perfilForm.get('cuit')?.touched" class="text-danger">
            CUIT o CUIL debe tener entre 10 y 13 caracteres y solo contener números.
          </div>
        </div>

        <div class="mb-3">
          <label for="provincia">Provincia:</label>
          <select id="provincia" name="provincia" class="form-select" formControlName="provincia">
            <option *ngFor="let provincia of provincias" [value]="provincia">{{ provincia }}</option>
          </select>
          <div *ngIf="perfilForm.get('provincia')?.invalid && perfilForm.get('provincia')?.touched" class="text-danger">
            Debe seleccionar una provincia.
          </div>
        </div>
      
        <div class="mb-3">
          <label for="localidad">Localidad:</label>
          <input type="text" id="localidad" name="localidad" class="form-control" formControlName="localidad">
          <div *ngIf="perfilForm.get('localidad')?.invalid && perfilForm.get('localidad')?.touched" class="text-danger">
            La localidad es obligatoria.
          </div>
        </div>

        <div class="mb-3">
          <label for="direccion">Dirección:</label>
          <input type="text" id="direccion" name="direccion" class="form-control" formControlName="direccion">
        </div>

        <div class="mb-3">
          <label for="telefono1">Teléfono 1:</label>
          <input type="tel" id="telefono1" name="telefono1" class="form-control" formControlName="tel1">
          <div *ngIf="perfilForm.get('tel1')?.invalid && perfilForm.get('tel1')?.touched" class="text-danger">
            Teléfono 1 debe tener entre 7 y 15 caracteres y solo contener números.
          </div>
        </div>

        <div class="mb-3">
          <label for="telefono2">Teléfono 2:</label>
          <input type="tel" id="telefono2" name="telefono2" class="form-control" formControlName="tel2">
          <div *ngIf="perfilForm.get('tel2')?.invalid && perfilForm.get('tel2')?.touched" class="text-danger">
            Teléfono 2 debe tener entre 7 y 15 caracteres y solo contener números.
          </div>
        </div>

        <button type="button" class="btn btn-primary d-flex align-items-center mb-5" (click)="guardarDatos()">
          <mat-icon>save</mat-icon>
          <span class="ms-2">Guardar</span>
        </button>
      </form>
    </div>
  </div>
</div>
