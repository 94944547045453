import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { catchError, map, Observable, switchMap, throwError } from 'rxjs';
import { CatalogoService } from './catalogo/catalogo.service';
import { Producto } from './catalogo/producto.interface';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private http: HttpClient,private storage: AngularFireStorage,
    private catalogoService:CatalogoService) {}
  csvFilePath = 'FVO/FVO.csv';
  imagenpath = 'FOTOS/';

  parseCsv(): Observable<Producto[]> {
    return this.storage.ref(this.csvFilePath).getDownloadURL().pipe(
      switchMap((url) => {
        return this.http.get(url, { responseType: 'text' });
      }),
      switchMap((data) => {
        const lines = data.split('\n');
        const products: Producto[] = [];
        const productCodes: string[] = [];

        for (let i = 2; i < lines.length; i++) {
          const line = lines[i].trim();
          if (line) {
            const parts = line.split(';');
            if (parts.length >= 5) {
              let codigo = parts[2].trim();
              codigo = codigo.replace(/\//g, '_');
              const producto: Producto = {
                marca: parts[0].trim(),
                rubro: parts[1].trim(),
                codigo: codigo,
                cantidad:0,
                descripcion: parts[3].trim(),
                precio: parseFloat(parts[4].replace(',', '.')), 
                imageUrl: '' // Marcador de posición para la URL de la imagen
              };
              products.push(producto);
              productCodes.push(codigo);
            }
          }
        }

        return this.catalogoService.getProductsInStockStatus(productCodes).pipe(
          map((stockStatuses) => {
            return products.map((product) => ({
              ...product,
              stock: stockStatuses[product.codigo] ? 'En Stock' : 'Sin Stock'
            }));
          })
        );
      })
    );
  }
  uploadCsv(file: File): Observable<number | undefined> {
    const filePath = this.csvFilePath;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    return task.percentageChanges();
  }
  uploadImages(image: File): Observable<number | undefined> {
    const filePath = `${this.imagenpath}/${image.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, image);
  
    return task.percentageChanges();
  }
  borrarImagenPorCodigo(codigo: string): Observable<string> {
    const filePath = `${this.imagenpath}/${codigo}.jpg`; // Asegúrate de ajustar la extensión si es necesario
    const fileRef = this.storage.ref(filePath);

    return fileRef.getDownloadURL().pipe(
      switchMap(() => {
        // Si la imagen existe, la eliminamos
        return fileRef.delete().pipe(
          map(() => 'Imagen eliminada con éxito.'),
          catchError((error) => {
            console.error('Error al eliminar la imagen:', error);
            return throwError(() => new Error('Ocurrió un error al intentar eliminar la imagen.'));
          })
        );
      }),
      catchError((error) => {
        if (error.code === 'storage/object-not-found') {
          return throwError(() => new Error('La imagen no existe.'));
        } else {
          console.error('Error al verificar la imagen:', error);
          return throwError(() => new Error('Ocurrió un error al verificar la imagen.'));
        }
      })
    );
  }
  // Método para obtener los códigos únicos
  cargaCodigos(): Observable<string[]> {
    return this.parseCsv().pipe(
      map((products) => {
        const codigos = new Set(products.map((producto) => producto.codigo));
        return Array.from(codigos);
      })
    );
  }

  // Método para obtener las marcas únicas
  cargaMarcas(): Observable<string[]> {
    return this.parseCsv().pipe(
      map((products) => {
        const marcas = new Set(products.map((producto) => producto.marca));
        return Array.from(marcas);
      })
    );
  }

  // Método para obtener los rubros únicos
  cargaRubros(): Observable<string[]> {
    return this.parseCsv().pipe(
      map((products) => {
        const rubros = new Set(products.map((producto) => producto.rubro));
        return Array.from(rubros);
      })
    );
  }
  
}
