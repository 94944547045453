import { Injectable } from '@angular/core';
import { Firestore,collection, doc,getDoc, getDocs} from '@angular/fire/firestore';
import { deleteDoc, setDoc, updateDoc } from 'firebase/firestore';
import { Observable, from } from 'rxjs';
import { map, mergeMap, switchMap, tap, toArray } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FacturaService {
  constructor(private firestore: Firestore) {}

  getNumerosDePedido(email: string): Observable<string[]> {
    const carritosCollection = collection(
      this.firestore,
      `facturas/pendientes/${email}/`
    );
    return from(getDocs(carritosCollection)).pipe(
      map((snapshot) => snapshot.docs.map((doc) => doc.id))
    );
  }

  getDetallesDeFactura(email: string, numeroDePedido: string): Observable<any> {
    const facturaDoc = doc(
      this.firestore,
      `facturas/pendientes/${email}/${numeroDePedido}`
    );
    return from(getDoc(facturaDoc)).pipe(
      map((docSnapshot) => {
        const data = docSnapshot.data();
        if (data) {
          if (data['fecha']) {
            data['fecha'] = data['fecha'].toDate(); // Convierte el Timestamp a Date
          }
          return { id: docSnapshot.id, ...data };
        } else {
          return { id: docSnapshot.id };
        }
      })
    );
  }
  getDetalle(email: string, numeroDePedido: string): Observable<any[]> {
    const detalleCollection = collection(
      this.firestore,
      `facturas/pendientes/${email}/${numeroDePedido}/detalle`
    );
    return from(getDocs(detalleCollection)).pipe(
      map((snapshot) =>
        snapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();
          return {
            codigoProducto: docSnapshot.id,
            ...data,
          };
        })
      )
    );
  }
  getFacturasConDetalles(email: string): Observable<any[]> {
    return this.getNumerosDePedido(email).pipe(
      switchMap((numerosDePedido) =>
        from(numerosDePedido).pipe(
          mergeMap((numeroDePedido) =>
            this.getDetallesDeFactura(email, numeroDePedido).pipe(
              map((detalles) => ({ numeroDePedido, ...detalles }))
            )
          ),
          toArray()
        )
      )
    );
  }
  getEmailsFacturas(): Observable<string[]> {
    const facturasCollection = collection(this.firestore, 'carritos');
    return from(getDocs(facturasCollection)).pipe(
      map(snapshot => snapshot.docs.map(doc => doc.id))
    );
  }

  async actualizarFactura(email: string, nPedido: string, detallesActualizados: any): Promise<void> {
    if (!email || !nPedido) {
      throw new Error('El email y el número de pedido son requeridos.');
    }

    try {
      // Ruta al documento que se actualizará
      const facturaRef = doc(this.firestore, `facturas/pendientes/${email}/${nPedido}`);

      // Actualiza los campos de la factura con los nuevos valores
      await updateDoc(facturaRef, detallesActualizados);
      console.log('Factura actualizada con éxito');
    } catch (error) {
      console.error('Error al actualizar la factura:', error);
      throw error;
    }
  }

}
