import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(private router: Router,
    private loginService: LoginService) {}
    
  login(form: NgForm){
    try {
      const email = form.value.email;
      const password = form.value.password;
      this.loginService.login(email,password);
    } catch (error) {
      console.log(error)
    }
  }
  
  registrar() {
    this.router.navigate(['/register']);
  }
  recuperar() {
    this.router.navigate(['/recovery']);
  }
}
