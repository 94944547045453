<div class="mt-3">

    <!-- Carrusel -->
    <div class="container">
        <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                    aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                    aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                    aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
                    aria-label="Slide 4"></button>
            </div>
            <div class="carousel-inner pointer-cursor">
                <div class="carousel-item active">
                    <img src="assets/LogoFVO.png" class="d-block w-100"
                        style="object-fit: scale-down;object-position: top;margin-top: 18px;">
                    <div class="carousel-caption mb-3">
                        <h2 class="welcome-text" style="text-decoration: underline;">Bienvenido</h2>
                        <p>Aqui encontraras los mejores repuestos al mejor precio.</p>
                    </div>
                </div>
                <div class="carousel-item" [routerLink]="['/principal/catalogo']">
                    <img src="assets/repuestos.png" class="d-block w-100" alt="...">
                    <div class="carousel-caption mb-3">
                        <p class="text-white slide-text-2">Visite nuestro catalogo --></p>
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="assets/auto.png" class="d-block w-100" alt="...">
                    <div class="carousel-caption mb-3">
                        <div class="bandera">
                            <p class="celeste text-white">Envios </p>
                            <p class="blanco">A Todo </p>
                            <p class="celeste text-white">El Pais</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" [routerLink]="['/principal/contacto']">
                    <img src="assets/contact.png" class="d-block w-100" alt="...">
                    <div class="carousel-caption mb-3">
                        <p class="text-white slide-text-4">Contactenos</p>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <!-- Carrusel de Imágenes -->
    <div class="image-carousel">
        <h3 class="text-center marcas-texto">¡Algunas de las marcas con las que trabajamos!</h3>
        <div class="image-carousel-track">
            <img *ngFor="let img of imagenesMarcas" [src]="img">
            <img *ngFor="let img of imagenesMarcas" [src]="img" class="duplicate">
        </div>
    </div>
    
    <!-- Panel rectangular -->
    <div class="d-flex justify-content-between flex-wrap">
        <!-- Panel rectangular 1 -->
        <div class="panel-rectangular1 pointer-cursor mt-5" [routerLink]="['/principal/catalogo']">
            <div class="text-container1">
                <h2>¡TENEMOS UN AMPLIO CATALOGO!</h2>
                <a>VER MÁS</a>
            </div>
            <div class="image-container1">
                <img src="assets/auto2.png" alt="Ofertas Especiales">
            </div>
        </div>
        
        <!-- Panel rectangular 2 -->
        <div class="panel-rectangular2 pointer-cursor mt-5" [routerLink]="['/principal/catalogo']">
            <div class="text-container2">
                <h2>No olvide completar sus datos.</h2>
                <a>Visite su Perfil</a>
            </div>
            <div class="image-container2">
                <img src="assets/planilla.jpg" alt="Ofertas Especiales">
            </div>
        </div>
    </div>

    <!-- Sección de ayuda y derechos -->
    <div class="footer mt-5">
        <p>¡Gracias por visitarnos!</p>
        <div class="contact-info">
            <p>Contactanos:</p>
            <p><a href="mailto:fvorepuestos@gmail.com">fvorepuestos&#64;gmail.com</a> | Tel: (+549) 3888 468916</p>
            <p class="copyright">Copyright 2024 © Fvo Repuestos. Todos los derechos reservados.</p>
        </div>
    </div>
</div>