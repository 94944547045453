import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { inject } from '@angular/core';
import { PageAdminService } from '../pageAdmin/page-admin.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private auth: Auth = inject(Auth);
  token: string | null = null;

  constructor(private router: Router,private pageAdminService:PageAdminService) {}
  login(email: string, password: string) {
    signInWithEmailAndPassword(this.auth, email, password)
      .then(async (response) => {
        const user = this.auth.currentUser;
        if (user) {
          this.token = await user.getIdToken();
          localStorage.setItem('token', this.token); // Guarda el token en localStorage
          this.router.navigate(['/principal']);
        }
      })
      .catch((error) => {
        console.error('Error en el inicio de sesión:', error);
        if (error.code === 'auth/wrong-password') {
          alert('Contraseña incorrecta.');
        } else if (error.code === 'auth/user-not-found') {
          alert('No se encontró un usuario con ese correo electrónico.');
        } else {
          alert(
            'Error en el inicio de sesión. Por favor, verifica tus credenciales.'
          );
        }
      });
  }
  getEmail(): string | null {
    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      return storedEmail;
    }
    const user = this.auth.currentUser;
    const userEmail = user ? user.email : null;
    if (userEmail) {
      localStorage.setItem('email', userEmail);
    }
    return userEmail;
  }
  isAutenticado() {
    return !!localStorage.getItem('token'); // Verifica si hay un token en localStorage
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        this.token = null;
        localStorage.removeItem('email');// Elimina el token de localStorage
        localStorage.removeItem('token'); // Elimina el token de localStorage
        localStorage.removeItem('admin');
        this.router.navigate(['login']);
      })
      .catch((error) => console.error('error logout:' + error));
  }
}
