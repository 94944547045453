export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCMN-RoiuYpQEk3GplpFiRhm8nlLGu78mM',
    authDomain: 'fvo-repuestos-app.firebaseapp.com',
    databaseURL: 'https://fvo-repuestos-app-default-rtdb.firebaseio.com',
    projectId: 'fvo-repuestos-app',
    storageBucket: 'fvo-repuestos-app.appspot.com',
    messagingSenderId: '406709533506',
    appId: '1:406709533506:web:3d0540ffeee39981bfe6db',
  },
};
