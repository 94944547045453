import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageAdminService } from './page-admin.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { catchError, finalize, map, startWith } from 'rxjs/operators';
import { CsvService } from '../csv.service';
import { AlertService } from '../alert.service';
import { CatalogoService } from '../catalogo/catalogo.service';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { Firestore, collection, deleteDoc, doc, getDoc, getDocs, setDoc, updateDoc } from '@angular/fire/firestore';
import { FacturaService } from '../cuenta-corriente/factura.service';

@Component({
  selector: 'app-page-admin',
  templateUrl: './page-admin.component.html',
  styleUrls: ['./page-admin.component.css'],
})
export class PageAdminComponent {
  stockForm: FormGroup;
  bannerForm: FormGroup;
  facturaForm: FormGroup;
  adminForm = FormGroup;
  productoControl = new FormControl();
  facturaEmailControl = new FormControl();
  facturaControl = new FormControl();
  fecha: Date | string = new Date();
  carritosIds: string[] = [];
  numerosDePedido: string[] = [];
  clientesEmails: string[] = [];
  codigoImagen: string = '';
  codigos: string[] = [];
  email: string = '';
  emails: string[] = [];
  filteredEmails: string[] = [];
  subtotal: number = 0;
  total: number = 0;
  loading = true;
  loadingEstado = false;
  loadingFactura = false;
  loadingPedidos = false;
  estado: boolean | null = null;
  estadoFactura: string = '';
  mostrarModal: boolean = false;
  selectedFile: File | null = null;
  selectedImages: File[] = [];
  facturaCargada = false;
  verEmails=false;
  detalles!: any[]; // Define detalles como un array de tipo any
  detalles$: Observable<any[]> | undefined; // Define detalles$ como un Observable
  filteredCodigos: Observable<string[]> = of([]);
 

  constructor(private router: Router,private pageAdminService: PageAdminService, private csvService: CsvService,
    private alertService: AlertService, private catalogoService: CatalogoService, private fb: FormBuilder,
    private firestore: Firestore, private facturaService: FacturaService  ) {
      this.facturaEmailControl = new FormControl('');
      this.facturaControl = new FormControl('');
  
      this.stockForm = this.fb.group({
        producto: [''],
      });
      this.bannerForm = this.fb.group({
        banner1: [''],
        banner2: [''],
        banner3: [''],
      });
      this.facturaForm = this.fb.group({
        nPedido: [''],
        subtotal: ['0'],
        iva: ['0'],
        descuento: ['0'],
        total: [{ value: '0', disabled: true }],
        fecha: [''],
        estado: ['']
      });
      // Suscríbete a los cambios en los valores del formulario
      this.facturaForm.valueChanges.subscribe(() => this.updateTotal());
  }

  ngOnInit(): void {
    this.cargarClientes();
    this.pageAdminService.isAdmin().subscribe((isAdmin) => {
      if (!isAdmin) {
        this.router.navigate(['/principal/catalogo']);
      }else{this.loading = false;}
    });
    const docRef = doc(this.firestore, 'configuracion/1');
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        this.bannerForm.patchValue({
          banner1: data?.['Banner1'] || '',
          banner2: data?.['Banner2'] || '',
          banner3: data?.['Banner3'] || '',
        });
      }
    });
    this.cargarCodigos();
    this.cargarEmails();
    this.facturaEmailControl.valueChanges.subscribe((value: string) => {
      this.filtrarEmails(value);
    });
    this.csvService.cargaCodigos().subscribe((codigos) => {
      this.codigos = codigos;
      this.filteredCodigos = this.productoControl.valueChanges.pipe(
        startWith(''),
        map((value) => {
          const inputValue = value as string;
          return inputValue.length >= 3
            ? this.filterOptions(inputValue, this.codigos)
            : [];
        })
      );
    });
    this.pageAdminService.getCarritosIds().subscribe(
      (ids) => {
        if (ids.length === 0) {
        } else {
          this.carritosIds = ids;
        }
      },
      (error) => {
        console.error('Error al obtener los IDs de carritos:', error);
      }
    );
    this.actualizarEstadoInput();
  }
  updateTotal(): void {
    const subtotalStr = this.facturaForm.get('subtotal')?.value || '0';
    const iva = this.facturaForm.get('iva')?.value || '0';
    const descuento = this.facturaForm.get('descuento')?.value || '0';
  
    // Convierte el subtotal de formato "$ 10,00" a número
    const subtotal = this.convertToNumber(subtotalStr);
  
    // Calcula el total
    const total = subtotal * (1 + parseFloat(iva.replace(',', '.')) / 100) * (1 - parseFloat(descuento.replace(',', '.')) / 100);
  
    // Formatea el total al formato deseado (con dos decimales y separador de miles)
    const formattedTotal = total.toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  
    // Actualiza el valor del total en el formulario sin emitir eventos
    this.facturaForm.get('total')?.setValue(`$ ${formattedTotal}`, { emitEvent: false });
  }
  
  convertToNumber(value: string): number {
    // Elimina el símbolo de moneda y los espacios en blanco
    let cleanedValue = value.replace(/[^0-9,,-]+/g, '');
    
    // Reemplaza el punto por un carácter temporal
    cleanedValue = cleanedValue.replace(/\./g, '');
    
    // Reemplaza la coma por un punto para convertir a número
    cleanedValue = cleanedValue.replace(',', '.');
    
    return parseFloat(cleanedValue) || 0;
  }
  
  actualizarEstadoInput(): void {
    if (this.numerosDePedido.length === 0) {
      this.facturaControl.disable();
    } else {
      this.facturaControl.enable();
    }
  }
  async actualizarBanner() {
    const { banner1, banner2, banner3 } = this.bannerForm.value;
    const docRef = doc(this.firestore, 'configuracion/1');
    this.alertService.showLoadingMessage();
    try {
      await updateDoc(docRef, {
        Banner1: banner1,
        Banner2: banner2,
        Banner3: banner3,
      });
      this.alertService.showSuccessMessage(
        'Banners actualizados correctamente'
      );
    } catch (error) {
      this.alertService.showErrorMessage(
        'Error al actualizar los banners: ' + error
      );
    }
  }
  private filterOptions(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
  cargarCodigos() {
    this.csvService.cargaCodigos().subscribe((codigos) => {
      this.codigos = codigos;
    });
  }
  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      if (input.id === 'base') {
        // Si el input tiene id 'base', entonces es un archivo CSV
        this.selectedFile = input.files[0]; // Solo un archivo CSV
      } else if (input.id === 'imagenes') {
        // Si el input tiene id 'imagenes', entonces son imágenes
        this.selectedImages = Array.from(input.files); // Convertir FileList a array
      }
    }
  }
  toggleVerEmails(): void {
    this.verEmails = !this.verEmails;
  }
  agregarImagenes(): void {
    if (!this.selectedImages || this.selectedImages.length === 0) {
      alert('Por favor, selecciona una o más imágenes.');
      return;
    }

    this.alertService.showLoadingMessage();

    // Procesar cada imagen seleccionada
    const uploadObservables = this.selectedImages.map((image) =>
      this.csvService.uploadImages(image)
    );

    // Ejecutar las cargas de imágenes en paralelo
    forkJoin(uploadObservables)
      .pipe(
        finalize(() => {
          this.alertService.showSuccessMessage('Imágenes subidas con éxito.');
        })
      )
      .subscribe();
  }
  borrarImagen(): void {
    if (!this.codigoImagen) {
      this.alertService.showErrorMessage(
        'Por favor, ingresa un código para eliminar la imagen.'
      );
      return;
    }

    this.csvService.borrarImagenPorCodigo(this.codigoImagen).subscribe({
      next: (mensaje: string) => {
        this.alertService.showSuccessMessage(mensaje);
      },
      error: (error: Error) => {
        this.alertService.showErrorMessage(error.message);
      },
    });
  }

  actualizarCsv(): void {
    if (!this.selectedFile) {
      alert('Por favor, selecciona un archivo CSV.');
      return;
    }
    this.alertService.showLoadingMessage();
    this.csvService.uploadCsv(this.selectedFile);
    this.alertService.showSuccessMessage('Archivo subido con éxito.');
  }
  async comprobarEstadoProducto() {
    this.loadingEstado = true;
    const codigo = this.productoControl.value;

    if (codigo) {
      // Verificar si el código está en la lista de códigos cargados
      if (this.codigos.includes(codigo)) {
        try {
          // Esperar la respuesta del servicio
          const inStock = await this.catalogoService
            .isProductInStock(codigo)
            .toPromise();
          this.estado = inStock !== undefined ? inStock : null;
        } catch (error) {
          console.error('Error checking stock status:', error);
          this.estado = null; // Error al consultar stock, se refleja como null
        }
      } else {
        this.estado = null;
        this.alertService.showErrorMessage('Código de producto no existente.');
      }
    } else {
      this.estado = null;
    }
    this.loadingEstado = false;
  }

  productoSinStock() {
    this.alertService.showLoadingMessage();
    this.catalogoService
      .addProductOutOfStock(this.productoControl.value)
      .subscribe(() => {
        this.comprobarEstadoProducto();
        this.alertService.showErrorMessage('Actualizado Producto SIN stock.');
      });
  }

  productoConStock() {
    this.alertService.showLoadingMessage();
    this.catalogoService
      .removeProductFromOutOfStock(this.productoControl.value)
      .subscribe(() => {
        this.comprobarEstadoProducto();
        this.alertService.showSuccessMessage('Actualizado Producto CON stock.');
      });
  }
  cargarEmails(): void {
    this.facturaService.getEmailsFacturas().subscribe({
      next: (emails) => {
        this.emails = emails;
      },
      error: (err) => {
        console.error('Error al cargar los emails:', err);
        this.emails = [];
      }
    });
  }
  cargarPedidosPorEmail(emailControl: FormControl) {
    this.loadingPedidos = true;

    const email = emailControl.value;

    this.facturaService.getNumerosDePedido(email).subscribe({
      next: (numerosDePedido) => {
        this.numerosDePedido = numerosDePedido; // Almacena los números de pedido para el autocompletado
        this.loadingPedidos = false;
        this.actualizarEstadoInput();
      },
      error: (err) => {
        console.error('Error al cargar los números de pedido:', err);
        this.loadingPedidos = false;
        this.numerosDePedido = [];
        this.actualizarEstadoInput();
      },
    });
    this.loadingPedidos = false;
  }

  comprobarEstadoFactura(nPedidoControl: FormControl) {
    this.loadingFactura = true;
    const email = this.facturaEmailControl.value;
    const nPedido = nPedidoControl.value;

    this.facturaService.getDetallesDeFactura(email, nPedido).subscribe({
      next: (detalles) => {
        if (detalles) {
          // Formatear la fecha manualmente
          if (detalles.fecha) {
            const date = new Date(detalles.fecha);
            this.facturaForm.patchValue({
              iva: detalles.iva,
              descuento: detalles.descuento,
              subtotal: detalles.subtotal,
              total: detalles.total,
              estado: detalles.estado,
              fecha: this.formatDate(date), // Usa la función de formato
            });
          }
          this.facturaCargada = true;
        } else {
          this.facturaCargada = false;
        }
        this.loadingFactura = false;
      },
      error: (err) => {
        console.error('Error al cargar la factura:', err);
        this.facturaCargada = false;
        this.loadingFactura = false;
      },
    });
  }

  // Función para formatear la fecha
  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  abrirModal(): void {
    const email = this.facturaEmailControl.value;
    const nPedido = this.facturaControl.value;
    this.detalles$ = this.facturaService.getDetalle(email, nPedido); // Asigna el Observable a detalles$

    this.detalles$.subscribe({
      next: (detalles) => {
        this.detalles = detalles; // Asigna el valor recibido a detalles
        this.mostrarModal = true;
      },
      error: (err) => {
        console.error('Error al cargar el detalle de la factura:', err);
        this.mostrarModal = false;
      },
    });
  }
  cerrarModal(): void {
    this.mostrarModal = false;
  }
  filtrarEmails(value: string): void {
    const filterValue = value.toLowerCase();
    
    // Filtra solo si hay al menos 3 caracteres
    if (filterValue.length >= 3) {
      this.filteredEmails = this.emails.filter(email => 
        email.toLowerCase().startsWith(filterValue)
      );
    } else {
      this.filteredEmails = [];
    }
  }
  aprobarFactura() {
    this.alertService.showLoadingMessage();
    const email = this.facturaEmailControl.value;
    const nPedido = this.facturaControl.value;

    // Agrega estos logs para verificar
    console.log('Email:', email);
    console.log('Número de Pedido:', nPedido);

    const detallesActualizados = {
      iva: this.facturaForm.get('iva')?.value,
      descuento: this.facturaForm.get('descuento')?.value,
      subtotal: this.facturaForm.get('subtotal')?.value,
      total: this.facturaForm.get('total')?.value,
      estado: 'Aprobada',
    };
  
    this.facturaService.actualizarFactura(email, nPedido, detallesActualizados)
      .then(() => {
        this.comprobarEstadoFactura(this.facturaControl);
        this.alertService.showSuccessMessage('Factura aprobada con éxito');
      })
      .catch((err) => {
        this.alertService.showErrorMessage('Error al aprobar la factura:'+ err);
      });
      
  }
  pendienteFactura(){
    this.alertService.showLoadingMessage();
    const email = this.facturaEmailControl.value;
    const nPedido = this.facturaControl.value;

    // Agrega estos logs para verificar
    console.log('Email:', email);
    console.log('Número de Pedido:', nPedido);

    const detallesActualizados = {
      iva: this.facturaForm.get('iva')?.value,
      descuento: this.facturaForm.get('descuento')?.value,
      subtotal: this.facturaForm.get('subtotal')?.value,
      total: this.facturaForm.get('total')?.value,
      estado: 'Pendiente',
    };
  
    this.facturaService.actualizarFactura(email, nPedido, detallesActualizados)
      .then(() => {
        this.comprobarEstadoFactura(this.facturaControl);
        this.alertService.showSuccessMessage('Factura regreso a estado Pendiente');
      })
      .catch((err) => {
        this.alertService.showErrorMessage('Error al regresar a Pendiente:'+ err);
      });
  }
  cancelarFactura() {
    this.alertService.showLoadingMessage();
    const email = this.facturaEmailControl.value;
    const nPedido = this.facturaControl.value;
    const detallesActualizados = {
      estado: 'Cancelada',
    };
  
    this.facturaService.actualizarFactura(email, nPedido, detallesActualizados)
      .then(() => {
        this.comprobarEstadoFactura(this.facturaControl);
        this.alertService.showSuccessMessage('Factura cancelada con éxito');
      })
      .catch((err) => {
        this.alertService.showErrorMessage('Error al cancelar la factura:'+ err);
      });
  }
  cargarClientes() {
    this.loading = true;
    this.pageAdminService.obtenerClientes().subscribe({
      next: (emails) => {
        this.clientesEmails = emails;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error al cargar los emails de clientes:', error);
        this.loading = false;
      }
    });
  }
  habilitarEmail() {
    if (!this.email) {
      this.alertService.showErrorMessage('Por favor, ingresa un email.');
      return;
    }

    const emailPath = `clientes/${this.email}`;
    const emailData = { email: this.email };

    setDoc(doc(this.firestore, emailPath), emailData)
      .then(() => {
        this.alertService.showConfirmMessageSuccess('Email habilitado con éxito.');
        this.cargarClientes();
      })
      .catch((error) => {
        console.error('Error habilitando el email:', error);
        this.alertService.showErrorMessage('Hubo un problema al habilitar el email. Inténtalo de nuevo.');
      });      
  }
  deshabilitarEmail(cEmail:string) {
    const emailPath = `clientes/${cEmail}`;
    deleteDoc(doc(this.firestore, emailPath))
      .then(() => {
        this.alertService.showConfirmMessageSuccess('Email deshabilitado con éxito.');
        this.cargarClientes(); // Actualiza la lista después de deshabilitar el email
      })
      .catch((error) => {
        console.error('Error deshabilitando el email:', error);
        this.alertService.showErrorMessage('Hubo un problema al deshabilitar el email. Inténtalo de nuevo.');
      });
  }
}
