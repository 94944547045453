import { Injectable } from '@angular/core';
import { Firestore, doc, getDoc, collection, getDocs } from '@angular/fire/firestore';
import { deleteDoc, setDoc } from 'firebase/firestore';
import { from, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {

  private readonly collectionName = 'sinStock'; // Nombre de la colección en Firestore

  constructor(private firestore: Firestore
  ) {}

  // Método para agregar un código a la colección 'sinStock'
  addProductOutOfStock(codigo: string): Observable<void> {
    const docRef = doc(this.firestore, this.collectionName, codigo);
    return from(setDoc(docRef, {})).pipe(
      map(() => {})
    );
  }

  // Método para eliminar un código de la colección 'sinStock'
  removeProductFromOutOfStock(codigo: string): Observable<void> {
    const docRef = doc(this.firestore, this.collectionName, codigo);
    return from(deleteDoc(docRef));
  }
  
  
  isProductInStock(codigo: string): Observable<boolean> {
    const docRef = doc(this.firestore, `sinStock/${codigo}`);
    return from(getDoc(docRef)).pipe(
      map((docSnapshot) => !docSnapshot.exists())
    );
  }
  getProductsInStockStatus(codes: string[]): Observable<{ [code: string]: boolean }> {
    const collectionRef = collection(this.firestore, 'sinStock');
    return from(getDocs(collectionRef)).pipe(
      map((querySnapshot) => {
        const stockStatuses: { [code: string]: boolean } = {};
        querySnapshot.forEach((doc) => {
          const codigo = doc.id;
          stockStatuses[codigo] = false; // No está en stock
        });

        // Marcar como en stock todos los códigos que no estén en la colección sinStock
        codes.forEach((code) => {
          if (!(code in stockStatuses)) {
            stockStatuses[code] = true; // Está en stock
          }
        });

        return stockStatuses;
      })
    );
  }
  getStockStatus(): Observable<{ [key: string]: boolean }> {
    const collectionRef = collection(this.firestore, 'sinStock');
    return from(getDocs(collectionRef)).pipe(
      map((querySnapshot) => {
        const stockStatus: { [key: string]: boolean } = {};
        querySnapshot.forEach((doc) => {
          stockStatus[doc.id] = false; // false indicates out of stock
        });
        return stockStatus;
      })
    );
  }
}
