import { Component, OnInit } from '@angular/core';
import { ImageService } from '../image.service';
import { CsvService } from '../csv.service';
import { CarritoService } from '../carrito.service';
import { Producto } from './producto.interface';
import { AlertService } from '../alert.service';
import { FormControl } from '@angular/forms';
import { filter, map, Observable, startWith } from 'rxjs';
@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css'],
})
export class CatalogoComponent implements OnInit {
  productos: Producto[] = [];
  productosCarrito: Producto[] = [];
  productosFiltrados: any[] = [];
  marcaFiltrada = new FormControl('');
  rubroFiltrado = new FormControl('');
  codigoFiltrado = new FormControl('');
  filtroGeneral: string = '';
  imagesUrls: { [key: string]: string } = {};
  isGridView = true;
  isListView = false;
  pageIndex = 0;
  pageSize = 12;
  loading = false;
  filtroTexto: string = '';

  marcasFiltradas: string[] = [];
  rubrosFiltrados: string[] = [];
  codigosFiltrados: string[] = [];

  // Aquí deberías tener tus listas originales de marcas, rubros y códigos
  marcas: string[] = [];
  rubros: string[] = [];
  codigos: string[] = [];

  

  filteredMarcas: Observable<string[]> = new Observable();
  filteredRubros: Observable<string[]> = new Observable();
  filteredCodigos: Observable<string[]> = new Observable();

  constructor(
    private carritoService: CarritoService,
    private imageService: ImageService,
    private csvService: CsvService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
      this.loading = true;
      this.csvService.parseCsv().subscribe((products) => {
        this.productos = products;
        this.productosFiltrados = [...this.productos];
        this.loadImagesForVisibleProducts();
      });
    this.csvService.cargaCodigos().subscribe((codigos) => {
      this.codigos = codigos;
      this.filteredCodigos = this.codigoFiltrado.valueChanges.pipe(
        startWith(''),
        map(value => {
          const inputValue = value as string;
          return inputValue.length >= 3 ? this.filterOptions(inputValue, this.codigos) : [];
        })
      );
    });
    this.csvService.cargaMarcas().subscribe((marcas) => {
      this.marcas = marcas;
      this.setupFilteredMarcas();
    });
    this.csvService.cargaRubros().subscribe((rubros) => {
      this.rubros = rubros;
      this.setupFilteredRubros();
    });
  }
  private filterOptions(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }
  toggleView(view: string): void {
    if (view === 'grid') {
      this.isGridView = true;
      this.isListView = false;
    } else if (view === 'list') {
      this.isGridView = false;
      this.isListView = true;
    }
  }
  get tituloCatalogo(): string {
    return this.filtroTexto.trim() === '' ? 'Catálogo' : `Buscando: ${this.filtroTexto}`;
  }
  filtrarProductos() {
    // Obtener los valores de los filtros, asegurándose de que sean cadenas
    const filtroGeneral: string = this.filtroGeneral.trim().toLowerCase();
    const codigoFiltrado: string = this.codigoFiltrado.value?.trim().toLowerCase() || '';
    const marcaFiltrada: string = this.marcaFiltrada.value?.trim().toLowerCase() || '';
    const rubroFiltrado: string = this.rubroFiltrado.value?.trim().toLowerCase() || '';
  
    // Aplicar los filtros a los productos
    if (filtroGeneral !== '') {
      const palabrasFiltroGeneral = filtroGeneral.split(' ');
  
      this.productosFiltrados = this.productos.filter(producto => {
        const descripcionCompleta = `${producto.codigo} ${producto.marca} ${producto.rubro} ${producto.descripcion}`.toLowerCase();
        return palabrasFiltroGeneral.every(palabra => descripcionCompleta.includes(palabra));
      });
  
      // Actualizar el texto del filtro para incluir el filtro general
      this.filtroTexto = [
        filtroGeneral ? `${filtroGeneral.toUpperCase()}` : '',
        codigoFiltrado ? `Código: ${codigoFiltrado.toUpperCase()}` : '',
        marcaFiltrada ? `Marca: ${marcaFiltrada.toUpperCase()}` : '',
        rubroFiltrado ? `Rubro: ${rubroFiltrado.toUpperCase()}` : ''
      ].filter(Boolean).join(', ');
    } else {
      this.productosFiltrados = this.productos.filter(
        (producto) =>
          (codigoFiltrado ? producto.codigo.toLowerCase().includes(codigoFiltrado) : true) &&
          (marcaFiltrada ? producto.marca.toLowerCase().includes(marcaFiltrada) : true) &&
          (rubroFiltrado ? producto.rubro.toLowerCase().includes(rubroFiltrado) : true)
      );
  
      // Actualizar el texto del filtro sin el filtro general
      this.filtroTexto = [
        codigoFiltrado ? `Código: ${codigoFiltrado.toUpperCase()}` : '',
        marcaFiltrada ? `Marca: ${marcaFiltrada.toUpperCase()}` : '',
        rubroFiltrado ? `Rubro: ${rubroFiltrado.toUpperCase()}` : ''
      ].filter(Boolean).join(', ');
    }
  
    // Restablecer el índice de la página y cargar imágenes para los productos visibles
    this.pageIndex = 0;
    this.loadImagesForVisibleProducts();
  }
  
  
  limpiarFiltros() {
    this.marcaFiltrada.setValue('');
    this.rubroFiltrado.setValue('');
    this.codigoFiltrado.setValue('');
    this.filtroGeneral = ''; // Esto se mantiene como un string, ya que es para el filtro general
    this.filtrarProductos();
  }
  async agregarProducto(producto: Producto) {
    const cantidadInput = await this.alertService.showInputQuantityAlert();
    if (cantidadInput !== null && cantidadInput > 0) {
      const productoConCantidad: Producto = { ...producto, cantidad: cantidadInput };
      await this.carritoService.addCarrito(productoConCantidad);
    }
  }
  async loadImagesForVisibleProducts() {
    this.loading = true;
    const productosVisibles = this.isGridView
      ? this.paginatedProducts
      : this.isListView
      ? this.paginatedProducts // Para vista de lista, usa paginatedProducts
      : [];
  
    // Cargar imágenes solo para productos visibles
    const loadImagePromises = productosVisibles.map(async (producto) => {
      if (!this.imagesUrls[producto.codigo]) { // Solo carga si aún no se ha cargado
        await this.loadImageUrl(producto.codigo);
      }
    });
  
    // Esperar a que todas las imágenes visibles se carguen
    await Promise.all(loadImagePromises);
  
    // Eliminar imágenes no visibles
    Object.keys(this.imagesUrls).forEach((codigo) => {
      if (!productosVisibles.some(p => p.codigo === codigo)) {
        delete this.imagesUrls[codigo]; // Eliminar imágenes no visibles
      }
    });
    this.loading = false;
  }
  
  verImagen(producto: Producto) {
    const imageUrl = this.imagesUrls[producto.codigo] || 'assets/noimagen.png';
    this.alertService.showPicture(producto.codigo,imageUrl);
  }
  loadImageUrl(codigo: string) {
    this.imageService.getImageUrlByCode(codigo).subscribe((url) => {
      this.imagesUrls[codigo] = url;
    });
  }
  get paginatedProducts() {
    const start = this.pageIndex * this.pageSize;
    const end = start + this.pageSize;
    return this.productosFiltrados.slice(start, end);
  }
  onPageChange(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadImagesForVisibleProducts();
  }
  filterMarcas(rubroValue: string): string[] {
    const filteredByRubro = this.productos
      .filter(producto => producto.rubro.toLowerCase().includes(rubroValue.toLowerCase()))
      .map(producto => producto.marca);
    
    return [...new Set(filteredByRubro)].sort();
  }
  filterRubros(marcaValue: string): string[] {
    const filteredByMarca = this.productos
      .filter(producto => producto.marca.toLowerCase().includes(marcaValue.toLowerCase()))
      .map(producto => producto.rubro);
    
    return [...new Set(filteredByMarca)].sort();
  }
  setupFilteredMarcas(): void {
    this.filteredMarcas = this.rubroFiltrado.valueChanges.pipe(
      startWith(this.rubroFiltrado.value),
      map(rubroValue => this.filterMarcas(rubroValue as string))
    );

    this.rubroFiltrado.valueChanges.subscribe(rubroValue => {
      this.marcaFiltrada.setValue(''); // Reset marcaFiltrada when rubroFiltrado changes
      this.filteredMarcas = this.rubroFiltrado.valueChanges.pipe(
        startWith(rubroValue),
        map(value => this.filterMarcas(value as string))
      );
    });
  }
  setupFilteredRubros(): void {
    this.filteredRubros = this.marcaFiltrada.valueChanges.pipe(
      startWith(this.marcaFiltrada.value),
      map(marcaValue => this.filterRubros(marcaValue as string))
    );
  }
}
