import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  imagenesMarcas: string[] = [];

  ngOnInit(): void {
    this.cargarImgMarcas();
  }

  cargarImgMarcas(): void {
    const numImagenes = 17; // Reemplaza con el número total de imágenes
    for (let i = 0; i < 5; i++) {
      for (let i = 1; i <= numImagenes; i++) {
        this.imagenesMarcas.push(`assets/marcas/marca${i}.png`);
      }
    }
  }
}
