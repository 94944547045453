import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Firestore, collection, doc, getDoc, getDocs,deleteDoc } from '@angular/fire/firestore';
import { Observable,catchError,from, map, switchMap, throwError  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageAdminService {
  constructor(private firestore: Firestore, private auth: Auth) {}
  
  isAdmin(): Observable<boolean> {
    return new Observable(observer => {
      this.auth.onAuthStateChanged(user => {
        if (!user || !user.email) {
          observer.next(false);
          observer.complete();
          return;
        }
        
        const userDocRef = doc(this.firestore, `configuracion/${user.email}`);
        getDoc(userDocRef).then(docSnapshot => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            observer.next(data?.["admin"] === true);
          } else {
            observer.next(false);
          }
          observer.complete();
        }).catch(error => {
          console.error('Error al verificar admin:', error);
          observer.next(false);
          observer.complete();
        });
      });
    });
  }
  
  getCarritosIds(): Observable<string[]> {
    const carritosCollection = collection(this.firestore, 'carritos');
    return from(getDocs(carritosCollection)).pipe(
      map(querySnapshot => {
        return querySnapshot.docs.map(doc => doc.id);
      }),
      catchError(error => {
        return [[]]; // Devuelve un array vacío en caso de error
      })
    );
  }
  obtenerClientes(): Observable<string[]> {
    const clientesRef = collection(this.firestore, 'clientes');
    return from(getDocs(clientesRef)).pipe(
      map(querySnapshot => querySnapshot.docs.map(doc => doc.id)),
      catchError(error => {
        console.error('Error al obtener los emails de clientes:', error);
        return throwError(() => new Error('Error al obtener los emails.'));
      })
    );
  }
  
}
