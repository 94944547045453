import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CambioPassServiceService } from './cambio-pass.service.service';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-cambio-pass',
  templateUrl: './cambio-pass.component.html',
  styleUrls: ['./cambio-pass.component.css']
})
export class CambioPassComponent {
  cambioPassForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private cambioPassService: CambioPassServiceService,
    private alertService:AlertService
  ) {
    this.cambioPassForm = this.fb.group({
      actualPass: ['', [Validators.required, Validators.minLength(6)]],
      newPass: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPass: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  passwordMatchValidator(form: FormGroup) {
    return form.get('newPass')!.value === form.get('confirmNewPass')!.value ? null : { mismatch: true };
  }

  async onChangePass() {
    if (this.cambioPassForm.valid) {
      const actualPass = this.cambioPassForm.get('actualPass')!.value;
      const newPass = this.cambioPassForm.get('newPass')!.value;
  
      await this.cambioPassService.cambiarContraseña(actualPass, newPass);
  
      // Resetea el formulario después de cambiar la contraseña
      this.cambioPassForm.reset();
    } else {
      // Aquí puedes manejar la validación de errores, como mostrar un mensaje de error si las contraseñas no coinciden
      this.alertService.showErrorMessage('El formulario es inválido o las contraseñas no coinciden.');
    }
  }
}
