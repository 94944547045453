<!-- Cargando spinner -->
<div *ngIf="loading" class="text-center" style="margin-top: 8rem;">
  <div class="spinner-border" role="status">
    <span class="sr-only"></span>
  </div>
  <p>Cargando...</p>
</div>

<!-- Datos de las facturas -->
<div class="container" *ngIf="!loading">
  <div class="row" *ngIf="(facturas | async)?.length === 0">
    <div class="col-12 text-center my-5">
      <h1>No hay elementos en la cuenta corriente</h1>
    </div>
  </div>

  <!-- Vista de lista -->
  <div *ngIf="(facturas | async)?.length" class="table-responsive col-11">
    <!-- Texto de advertencia -->
    <div class="alert alert-info text-center my-3" style="font-weight: bold;">
      Para ver el detalle de la factura pulse Click en una fila
    </div>
    <table class="table table-striped table-hover" style="background-color: rgb(255, 255, 255);">
      <thead class="thead-dark">
        <tr>
          <th scope="col">Número de Pedido</th>
          <th scope="col">Fecha</th>
          <th scope="col">Iva</th>
          <th scope="col">Descuento</th>
          <th scope="col">Subtotal</th>
          <th scope="col">Total</th>
          <th scope="col">Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let factura of facturas | async" (click)="abrirModal(factura)">
          <td>{{ factura.numeroDePedido }}</td>
          <td>{{ factura.fecha | date:'short' }}</td>
          <td>{{ factura.iva }}%</td>
          <td>{{ factura.descuento }}%</td>
          <td>{{ factura.subtotal }}</td>
          <td>{{ factura.total }}</td>
          <td [ngClass]="{
            'estado-pendiente': factura.estado === 'Pendiente',
            'estado-aprobado': factura.estado === 'Aprobada',
            'estado-cancelado': factura.estado === 'Cancelada'}">
            {{ factura.estado }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Modal -->
  <div class="modal" tabindex="-1" role="dialog" *ngIf="mostrarModal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Detalles del Pedido {{ facturaSeleccionada?.numeroDePedido }}</h5>
        </div>
        <div class="modal-body">
          <!-- Contenedor scrollable para la tabla -->
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Producto</th>
                  <th scope="col">Marca</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col">Precio</th>
                  <th scope="col">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let detalle of detalles | async">
                  <td>{{ detalle.codigo }}</td>
                  <td>{{ detalle.descripcion }}</td>
                  <td>{{ detalle.marca }}</td>
                  <td>{{ detalle.cantidad }}</td>
                  <td>{{ detalle.precio | currency:'USD':'symbol'}}</td>
                  <td>{{ (detalle.cantidad * detalle.precio) | currency:'USD':'symbol'}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Labels para mostrar los valores -->
          <div class="factura-info my-2 text-right">
            <label><strong>Subtotal:</strong> {{ facturaSeleccionada?.subtotal }}</label>
            <label><strong>IVA:</strong> %{{ facturaSeleccionada?.iva }}</label>
            <label><strong>Descuento:</strong> %{{ facturaSeleccionada?.descuento }}</label>
            <label><strong>Total:</strong> {{ facturaSeleccionada?.total }}</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="cerrarModal()">Cerrar</button>
        </div>
      </div>
    </div>
  </div>

</div>