import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { RegisterService } from './register.service';
import { AlertService } from '../alert.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  constructor(private router: Router,
    private registerService:RegisterService,
    private alertService:AlertService
  ){}
  emailHabilitado = false;
  // registrar(form: NgForm) {
    
  //   if (form.valid && this.emailHabilitado) {
  //     const { email, password } = form.value;
  //     this.registerService.register(email, password).subscribe({
  //       next: (user) => {
  //         this.alertService.showConfirmMessageSuccess('Se le envio un correo de verificacion. Una vez verificado, puede ingresar.');
  //         this.router.navigate(['/login']);
  //       },
  //       error: (error) => {
  //         this.alertService.showErrorMessage('Hubo un problema al registrarse. Inténtalo de nuevo.');
  //       }
  //     });
  //   }
  // }
  // registrar(form: NgForm) {
  //   if (form.valid) {
  //     const { email, password } = form.value;

  //     this.registerService.verificarEmailEnClientes(email).subscribe({
  //       next: (emailHabilitado) => {
  //         if (emailHabilitado) {
  //           this.registerService.register(email, password).subscribe({
  //             next: (user) => {
  //               this.alertService.showConfirmMessageSuccess('Su cuenta se creo con exito, ya puede ingresar.');
  //               this.router.navigate(['/login']);
  //             },
  //             error: (error) => {
  //               this.alertService.showErrorMessage('Hubo un problema al registrarse. Inténtalo de nuevo.');
  //             }
  //           });
  //         } else {
  //           this.alertService.showErrorMessage('El email requiere autorización del administrador.');
  //         }
  //       },
  //       error: (error) => {
  //         this.alertService.showErrorMessage('Error al verificar el email.');
  //       }
  //     });
  //   }
  // }
  registrar(form: NgForm) {
    if (form.valid) {
      const { email, password } = form.value;

      this.registerService.verificarEmailEnClientes(email).subscribe({
        next: (emailHabilitado) => {
          if (emailHabilitado) {
            this.registerService.register(email, password).subscribe({
              next: (user) => {
                this.alertService.showConfirmMessageSuccess('Su cuenta se creó con éxito, ya puede ingresar.');
                this.router.navigate(['/login']);
              },
              error: (error) => {
                console.error('Error en el registro:', error); // Debugging
                this.alertService.showErrorMessage('Hubo un problema al registrarse. Inténtalo de nuevo.');
              }
            });
          } else {
            this.alertService.showErrorMessage('El email requiere autorización del administrador.');
          }
        },
        error: (error) => {
          console.error('Error al verificar el email:', error); // Debugging
          this.alertService.showErrorMessage('Error al verificar el email.');
        }
      });
    }
  }


  volver(){
    this.router.navigate(['/login']);
  }
}
