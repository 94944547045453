import { Auth, createUserWithEmailAndPassword, User,sendEmailVerification } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { docData, Firestore } from '@angular/fire/firestore';
import { doc, getDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private auth: Auth, private firestore:Firestore) { }

  // verificarEmailEnClientes(email: string): Observable<boolean> {
  //   const docRef = doc(this.firestore, `clientes/${email}`);
  //   return from(getDoc(docRef)).pipe(
  //     switchMap(docSnapshot => {
  //       return docSnapshot.exists() ? from([true]) : from([false]);
  //     })
  //   );
  // }
  verificarEmailEnClientes(email: string): Observable<boolean> {
    const docRef = doc(this.firestore, `clientes/${email}`);
    return from(getDoc(docRef)).pipe(
      map(docSnapshot => docSnapshot.exists()) // Devuelve true si el documento existe
    );
  }
  
  // register(email: string, password: string): Observable<User> {
  //   return this.verificarEmailEnClientes(email).pipe(
  //     switchMap(emailHabilitado => {
  //       if (emailHabilitado) {
  //         // Si el correo está en la colección 'clientes', procedemos con el registro
  //         return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
  //           switchMap(userCredential => {
  //             const user = userCredential.user;
  //             return from(sendEmailVerification(user)).pipe(
  //               switchMap(() => from([user]))
  //             );
  //           }),
  //           catchError(error => throwError(() => error))
  //         );
  //       } else {
  //         // Si no está en la colección, lanzamos un error personalizado
  //         return throwError(() => new Error('Requiere autorización del administrador.'));
  //       }
  //     }),
  //     catchError(error => throwError(() => error))
  //   );
  // }
  register(email: string, password: string): Observable<User> {
    return this.verificarEmailEnClientes(email).pipe(
      switchMap(emailHabilitado => {
        console.log('Email habilitado:', emailHabilitado); // Debugging
        if (emailHabilitado) {
          // Si el correo está en la colección 'clientes', procedemos con el registro
          return from(createUserWithEmailAndPassword(this.auth, email, password)).pipe(
            map(userCredential => {
              console.log('Usuario creado:', userCredential.user); // Debugging
              return userCredential.user; // Retorna el usuario creado
            }),
            catchError(error => {
              console.error('Error al crear usuario:', error); // Debugging
              return throwError(() => error);
            })
          );
        } else {
          console.error('Email no autorizado'); // Debugging
          return throwError(() => new Error('Requiere autorización del administrador.'));
        }
      }),
      catchError(error => {
        console.error('Error en el proceso de verificación o registro:', error); // Debugging
        return throwError(() => error);
      })
    );
  }


}
