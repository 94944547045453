import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  showSuccessMessage(message: string) {
    Swal.fire({
      toast: true,
      position: 'top',
      icon: 'success',
      text: message,
      showConfirmButton: false,
      timer: 1000
    });
  }
  showPicture(codigo: string,url : string) {
    Swal.fire({
      title: codigo,
      imageUrl: url,
      imageWidth: 600,
      imageHeight: 400,
      imageAlt: "error"
    });
  }
  
  showErrorMessage(message: string) {
    Swal.fire({
      toast: true,
      position: 'top',
      icon: 'error',
      text: message,
      showConfirmButton: false,
      timer: 1000
    });
  }

  showConfirmMessageSuccess(message: string) {
    Swal.fire({
      icon: 'success',
      text: message,
      showConfirmButton: true
    });
  }
  showConfirmMessageError(message: string) {
    Swal.fire({
      icon: 'error',
      text: message,
      showConfirmButton: true
    });
  }
  showLoadingMessage() {
    Swal.fire({
      text: 'Cargando...',
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
  }  
  async showYesNoMessage(message: string, confirmMessage?: string): Promise<boolean> {
    return await Swal.fire({
      icon: 'question',
      showDenyButton: true,
      text: message,
      confirmButtonText: "Confirmar",
      confirmButtonColor: '#007bff',
      denyButtonText: `Cancelar`
    }).then((result) => {
      if (result.isConfirmed && confirmMessage) {
        Swal.fire(confirmMessage, "", "success");
      }
      return result.isConfirmed;
    });
  }
  
  async showInputQuantityAlert(): Promise<number | null> {
    const { value: cantidad } = await Swal.fire({
      title: 'Ingresar cantidad',
      input: 'number',
      inputLabel: 'Cantidad',
      inputPlaceholder: 'Ingrese la cantidad',
      inputAttributes: {
        min: '1',
        step: '1'
      },
      showCancelButton: true,
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#007bff',
      preConfirm: (cantidad) => {
        if (!cantidad || cantidad <= 0) {
          Swal.showValidationMessage('Ingrese una cantidad válida');
          return null;
        }
        return cantidad;
      }
    });

    return cantidad ? parseInt(cantidad, 10) : null;
  }

}